import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";


@Injectable({
  providedIn: "root"
})

export class BreadCrumbService {

  partTypeData = null;
  cpData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  ccData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  brData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  pdData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  srchData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  triggerYMM: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  triggerProductScroll: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  triggerPYMM: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  allProductsYearMakeModel: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  preSelectMakeYearModel: Subject<any> = new Subject<any>();
  savedAddVehicle = null;

  constructor() { }


  setPartTypeData(searchkeyword) {
    this.cpData.next({
      partTypeData: { partTypeId: searchkeyword.part_type_id, partName: searchkeyword.part_type_name },
      categoryData: { categoryId: searchkeyword.category_id, categoryName: searchkeyword.category_name, redirectToPage: this.redirectToPage(searchkeyword.category_url) }
    });
  }


  setCategoryData(categoryId, categoryName) {
    this.ccData.next({ categoryId: categoryId, categoryName: categoryName });
  }

  setBrandData(brandId, brandName) {
    this.brData.next({ brandId: brandId, brandName: brandName });
  }

  setSearchData(data) {
    this.srchData.next(
      {
        yearname: data.yearname,
        year_id: data.year_id,
        makename: data.makename,
        make_id: data.make_id,
        model_id: data.model_id,
        modelname: data.modelname,
        partname: data.partname,
        parturl: this.redirectToPage(data.parturl),
        part_id: data.part_id,
        category_id: data.category_id,
        categoryname: data.categoryname,
        categoryurl: this.redirectToPage(data.categoryurl),
      }
    );
  }


  setProductDetails(data) {
    this.pdData.next(
      {
        category_url: this.redirectToPage(data.category_url),
        part_category: data.part_category,
        part_type: data.part_type,
        part_type_url: this.redirectToPage(data.part_type_url),
        part_id: data.part_type_id ? data.part_type_id : null,
        category_id: data.part_category_id ? data.part_category_id : null,
        productId: data.id,
        productName: data.nick_name ? data.nick_name : data.title,
        make: data.make,
        make_id: data.make_id,
        model: data.model,
        model_id: data.model_id,
        year: data.year,
        year_id: data.year_id,
        brand_id: data.brand_id,
        brand_name: data.brand_name,
        brand_url: this.redirectToPage(data.brand_url)
      }
    );
  }

  redirectToPage(element): string {
    const a = document.createElement("a");
    a.href = element;
    return a['pathname'];
  }


}
