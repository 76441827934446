import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LiveChatWidgetModule } from '@livechat/widget-angular';
import { LiveChatComponent } from '../livechat/livechat.component';

@NgModule({
  declarations: [
    LiveChatComponent
  ],
  imports: [
    CommonModule,
    LiveChatWidgetModule
  ],
  exports: [
    LiveChatComponent
  ]
})
export class LiveChatModule { }
