import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {LiveChatWidgetComponent as LiveChatWidgetModel, EventHandlerPayload } from "@livechat/widget-angular";
// import { EventHandlerPayload } from '@livechat/widget-angular'
import { DataService } from "src/app/shared/common-services/data.service";
import { environment } from "src/environments/environment";


@Component({
  selector: "app-livechat",
  templateUrl: "./livechat.component.html",
  styleUrls: ["./livechat.component.scss"]
})
export class LiveChatComponent implements OnInit {
  public isVisible: boolean = true;
  public isLiveChatWidgetLoaded: boolean = false;
  public visitor: { name: string; email: string };
  public params: { name: string; value: string }[];
  @ViewChild('liveChatWidget') public liveChatWidget: LiveChatWidgetModel;
  licenseId: any;

  constructor( private sharedSerive: DataService, @Inject(DOCUMENT) private document: Document,) {
    this.licenseId = environment.production ? 12159414 : 12159414;
  }

  ngOnInit() {
    this.initChat();
  }

  openChatWindow(): void {
    this.liveChatWidget.widget.updateVisibility('maximized');
  }

  hideChatWindow() {
    this.liveChatWidget.widget.updateVisibility('maximized');
  }

  showHideChatWindow(event){
    this.liveChatWidget.widget.updateVisibility(event.visibility);
  }

  initChat() {
    this.sharedSerive.showChatPopupTrigger.subscribe(resp => {
      if (resp == true) {
        this.openChatWindow();
      } else {
        this.hideChatWindow();
      }
    })
  }

  setChatActive() {
    this.sharedSerive.emitChatPopupActiveTrigger();
  }

}
