import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import { BreadCrumbService } from "../core/breadcrumbs.service";
import { DataService } from "src/app/shared/common-services/data.service";
import { SeoService } from "src/app/shared/common-services/seo.service";


@Component({
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.scss"],
  encapsulation: ViewEncapsulation.None,
})

export class BreadCrumbsComponent implements OnInit, OnDestroy {

  showBreadCrumbs = false;
  breadCrumbsArray = [];
  currentUrl: string = '';
  updated
  private ngUnsubscribe = new Subject<void>();
  page_isBrowser: boolean = false;

  constructor(
    private router: Router,
    private dataService: DataService,
    private breadCrumbService: BreadCrumbService,
    private seoService: SeoService
  ) {
    this.page_isBrowser = this.dataService.getIsBrowser();
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.showBreadCrumbs = false;
      this.breadCrumbsArray = [];
      this.currentUrl = event.url;
      this.routerEventsFunction();
    });

  }

  ngOnInit() {
    if(this.page_isBrowser){
      this.getSubscription();
    }
  }

  routerEventsFunction(): void {
    this.breadCrumbsArray = [{ type: 'home', name: 'Home', link: '/', isLink: true, id: -2, sort: 1 }, { type: 'allproducts', name: 'All Products', link: '', isLink: false, id: -1, sort: 2 }];
    this.showBreadCrumbs = this.seoService.seoData['showBreadCrumb'] ? this.seoService.seoData['showBreadCrumb'] : false;
  }


  getSubscription(): void {
    this.breadCrumbService.cpData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((x) => {
      if (x && Object.keys(x).length > 0) {
        this.deleteOldEntries(['allproducts', 'category', 'parttype']);
        this.setSubscriptionData('allproducts', { type: 'allproducts', name: 'All Products', link: '/all-products', isLink: true, id: -1, sort: 2 });
        this.setSubscriptionData('category', { type: 'category', name: x.categoryData.categoryName, link: x.categoryData.redirectToPage, isLink: true, id: x.categoryData.categoryId, click: false, sort: 3 });
        this.setSubscriptionData('parttype', { type: 'parttype', name: x.partTypeData.partName, link: '', isLink: false, id: x.partTypeData.partTypeId, click: false, sort: 4 })

      }
    });
    this.breadCrumbService.ccData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((x) => {
      if (x && Object.keys(x).length > 0) {
        this.deleteOldEntries(['allproducts', 'categoryfull']);
        this.setSubscriptionData('allproducts', { type: 'allproducts', name: 'All Products', link: '/all-products', isLink: true, id: -1, sort: 2 })
        this.setSubscriptionData('categoryfull', { type: 'categoryfull', name: x.categoryName, link: '', isLink: false, id: x.categoryId, click: false, sort: 3 });
      }
    });

    this.breadCrumbService.brData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((x) => {
      if (x && Object.keys(x).length > 0) {
        this.deleteOldEntries(['allproducts', 'brand']);
        this.setSubscriptionData('allproducts', { type: 'allproducts', name: 'All Products', link: '/all-products', isLink: true, id: -1, sort: 2 })
        this.setSubscriptionData('brand', { type: 'brand', name: x.brandName, link: '', isLink: false, id: x.brandId, click: false, sort: 3 });
      }
    });


    this.breadCrumbService.pdData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((x) => {
      if (x && Object.keys(x).length > 0) {
        this.deleteOldEntries(['allproducts', 'pbrand', 'ccategory', 'ppart', 'pmake', 'pmodel', 'pyear', 'product'])
        this.setSubscriptionData('allproducts', { type: 'allproducts', name: 'All Products', link: '/all-products', isLink: true, id: -1, sort: 2 })
        if (x.brand_name) {
          this.setSubscriptionData('pbrand', { type: 'pbrand', name: x.brand_name, link: x.brand_url ? x.brand_url : '', isLink: true, id: x.brand_id, click: x.brand_id == -1 ? true : false, sort: 3 })
        }
        if (x.part_category) {
          this.setSubscriptionData('ccategory', { type: 'ccategory', name: x.part_category, link: x.category_url, isLink: true, id: x.category_id, click: false, sort: 4 })
        }
        if (x.part_type) {
          this.setSubscriptionData('ppart', { type: 'ppart', name: x.part_type, link: x.part_type_url, isLink: true, id: x.part_id, click: false, sort: 5 })
        }
        if (x.make) {
          this.setSubscriptionData('pmake', { type: 'pmake', name: x.make, link: '', isLink: true, id: x.make_id, click: true, sort: 6 })
        }
        if (x.model) {
          this.setSubscriptionData('pmodel', { type: 'pmodel', name: x.model, link: '', isLink: true, id: x.model_id, click: true, sort: 7 })
        }
        if (x.year) {
          this.setSubscriptionData('pyear', { type: 'pyear', name: x.year, link: '', isLink: true, id: x.year_id, click: true, sort: 8 })
        }
        this.setSubscriptionData('product', { type: 'product', name: x.productName, link: '', isLink: false, id: x.productId, click: false, sort: 9 })
      }
    });

    this.breadCrumbService.srchData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((x) => {
      if (x && Object.keys(x).length > 0) {
        this.deleteOldEntries(['allproducts', 'scategory', 'part', 'make', 'model', 'year', 'sparts'])
        this.setSubscriptionData('allproducts', { type: 'allproducts', name: 'All Products', link: '/all-products', isLink: true, id: -1, sort: 2 })
        let params = x;
        if (Object.keys(params).length > 0) {
          if (params.categoryname) {
            this.setSubscriptionData('scategory', { type: 'scategory', name: params.categoryname, link: params.categoryurl ? params.categoryurl : '', isLink: params.categoryurl ? true : false, id: params.category_id, click: false, sort: 3 })
          }
          if (params.partname) {
            this.setSubscriptionData('part', { type: 'part', name: params.partname, link: params.parturl ? params.parturl : '', isLink: params.parturl ? true : false, id: params.part_id, click: false, sort: 4 })
          }
          if (params.makename) {
            this.setSubscriptionData('make', { type: 'make', name: params.makename, link: '', isLink: true, id: params.make_id, click: true, sort: 5 })
          }
          if (params.modelname) {
            this.setSubscriptionData('model', { type: 'model', name: params.modelname, link: '', isLink: true, id: params.model_id, click: true, sort: 6 })
          }
          if (params.yearname) {
            this.setSubscriptionData('year', { type: 'year', name: params.yearname, link: '', isLink: true, id: params.year_id, click: true, sort: 7 })
          }
        }
      }
    });

    this.breadCrumbService.allProductsYearMakeModel.pipe(takeUntil(this.ngUnsubscribe)).subscribe((x) => {
      if (x && Object.keys(x).length > 0) {
        if (x.makename) {
          this.deleteOldEntries(['allproducts', 'apmake', 'apyear', 'apmodel']);
        }

        if (x.makename && Object.keys(x.makename).length > 0) {
          this.setSubscriptionData('allproducts', { type: 'allproducts', name: 'All Products', link: '/all-products', isLink: true, id: -1, sort: 2 })
          this.setSubscriptionData('apmake', { type: 'apmake', name: x.makename, link: '', isLink: true, id: x.make_id, click: true, sort: 3 })
        }
        if (x.modelname && Object.keys(x.modelname).length > 0) {
          this.setSubscriptionData('apmodel', { type: 'apmodel', name: x.modelname, link: '', isLink: true, id: x.model_id, click: true, sort: 4 })
        }
        if (x.yearname && Object.keys(x.yearname).length > 0) {
          this.setSubscriptionData('apyear', { type: 'apyear', name: x.yearname, link: '', isLink: true, id: x.year_id, click: true, sort: 5 })
        }
      }
    });

  }

  deleteOldEntries(exempt) {
    let array = [];
    const defaultArrays = ['allproducts', 'apmake', 'apyear', 'apmodel', 'category', 'parttype', 'categoryfull', 'brand', 'pbrand', 'ccategory', 'ppart', 'pmake', 'pmodel', 'pyear', 'product', 'scategory', 'part', 'make', 'model', 'year', 'sparts']
    array = defaultArrays.filter(function (obj) { return exempt.indexOf(obj) == -1; });
    array.forEach(y => {
      let isFoundKey = this.checkWthetherkeyExist(y);
      if (isFoundKey) {
        this.breadCrumbsArray.splice(isFoundKey, 1);
      }
    });
  }

  checkWthetherkeyExist(key) {
    let isFoundKey = null;
    for (let i = this.breadCrumbsArray.length; i--;) {
      if (this.breadCrumbsArray[i].type == key) {
        isFoundKey = i;
      }
    }
    return isFoundKey;
  }

  setSubscriptionData(key, data) {
    let newEntry = this.checkWthetherkeyExist(key);
    if (!newEntry) {
      this.breadCrumbsArray.push(data)
    } else {
      this.breadCrumbsArray[newEntry] = data;
    }
    this.breadCrumbsArray = this.breadCrumbsArray.sort((a, b) => a.sort - b.sort);
    this.seoService.breadCrumbsData.next(this.breadCrumbsArray);
  }

  navigateEvents(items) {
    const searchQuery = ['make', 'model', 'year']
    if (searchQuery.includes(items.type)) {
      this.breadCrumbService.triggerYMM.next(items);
    }
    const scrollQuery = ['pbrand'];
    if (scrollQuery.includes(items.type)) {
      this.breadCrumbService.triggerProductScroll.next(true);
    }
    const searchPQuery = ['pmake', 'pmodel', 'pyear'];
    if (searchPQuery.includes(items.type)) {
      this.redirectToSearch(items);
    }
    const apSearchPQuery = ['apmake', 'apyear', 'apmodel'];
    if (apSearchPQuery.includes(items.type)) {
      this.breadCrumbService.preSelectMakeYearModel.next({ index: 2, type: items.type, breadCrumbsArray: this.breadCrumbsArray });
    }
  }

  onRouterChange(items): void {
    if (items.type == 'allproducts' && this.dataService.savedAddVehicle && Object.keys(this.dataService.savedAddVehicle).length > 0 && !this.currentUrl.includes('all-products')) {
      const trimText = (text) => text.replace(/\s+/g, '-').toLowerCase();
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate([`all-products/${trimText(this.dataService.savedAddVehicle.year)}/${trimText(this.dataService.savedAddVehicle.make)}/${trimText(this.dataService.savedAddVehicle.model)}`])
      );
    }

  }

  redirectToSearch(section) {
    const searchPQuery = ['pmake', 'pmodel', 'pyear', 'ppart'];
    let make, model, year, items: any = {};
    searchPQuery.forEach(y => {
      let filtered: any = this.breadCrumbsArray.filter(x => x.type == y);
      if (filtered && filtered.length > 0) {
        if (y == 'pmake') {
          make = { id: filtered[0].id, name: filtered[0].name };
        }
        if (y == 'pmodel') {
          model = { id: filtered[0].id, name: filtered[0].name };
        }
        if (y == 'pyear') {
          year = { id: filtered[0].id, name: filtered[0].name };
        }
        if (y == 'ppart') {
          items = { id: filtered[0].id, name: filtered[0].name, redirect_url: filtered[0].link };
        }
      }
    });
    this.dataService.setSearchLocalValues(year, make, model, items);
    sessionStorage.setItem('dropOpenSection', JSON.stringify(section))
    this.router.navigate([`search`],
      {
        queryParams: {
          yearid: year.id, yearname: year.name, makeid: make.id, makename: make.name, modelid: model.id, modelname: model.name,
          partid: items.id, partname: items.name, parturl: items.redirect_url
        },

      },
    );

  }



  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


}
