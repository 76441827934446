import { Inject, Injectable } from "@angular/core";
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute,  Router, RoutesRecognized } from "@angular/router";
import { SeoText } from "./seo-text"
import { Subject } from "rxjs";
import { DOCUMENT } from "@angular/common";
import { AppConstants } from "src/app/app.constants";


@Injectable({
    providedIn: "root"
})

export class SeoService {
    scriptType = 'application/ld+json';
    breadCrumbsData: Subject<any> = new Subject<any>();
    seoData = {};


    constructor(
        private title: Title, private meta: Meta,
        private router: Router, private route: ActivatedRoute,
        @Inject(DOCUMENT) private _document: Document,
    ) {


    }

    initiateRouter() {
        this.router.events.subscribe((data) => {
            if (data instanceof RoutesRecognized) {
                if (data.state.root.firstChild.data && Object.keys(data.state.root.firstChild.data).length > 0) {
                    this.seoData = data.state.root.firstChild.data;
                } else if (data.state.root.firstChild.firstChild.data && Object.keys(data.state.root.firstChild.firstChild.data).length > 0) {
                    this.seoData = data.state.root.firstChild.firstChild.data;
                } else {
                    this.seoData = data.state.root.firstChild.firstChild.firstChild.data;
                }
                setTimeout(() => {
                    this.initiateSeoTagUpdate(this.seoData);
                }, 0)
            }
        });

    }


    initiateSeoTagUpdate(event) {
        if (!event || Object.keys(event).length == 0) {
            this.setSeoValues(
                'AutoBuffy - Find Great Car Part Deals | Choose Your Vehicle',
                'Drivers love AutoBuffy. You can find great deals on all kinds of auto parts. Search by make and model of your car, by auto parts brand, or by parts type',
                '',
                'https://www.autobuffy.com/assets/images/home/home-banner-img.webp');

        } else {
            if (window.location.href.includes("/category/")) {
              let seodata = SeoText.SeoArray(window.location.href);
                if (seodata && Object.keys(seodata).length > 0) {
                    this.setSeoValues(seodata.title, seodata.description, '', seodata.image);
                }
            } else {
                this.setSeoValues(event.title, event.description, '', event.image);
            }
        }

    }

    setSeoValues(title, description, url, image) {
        this.updateTitle(title);
        this.updateMetaTagTitle(title);
        this.updateMetaTagDescription(description);
        this.updateMetaTagUrl(url);
        this.updateMetaTagImage(image)
        this.seoData = {
            title: title,
            description: description,
            image: image,
            url: url,
            showBreadCrumb: this.seoData["showBreadCrumb"],
            addMyM: this.seoData["addMyM"],
            showSecondaryFooter:this.seoData["showSecondaryFooter"]
        };

    }


    updateTitle(title: string) {
        if (title) {
            this.title.setTitle(title);
        }

    }

    updateMetaTagTitle(title) {
        this.meta.updateTag({ content: title ? title : '' }, 'name="title"');
        this.meta.updateTag({ content: title ? title : '' }, 'property="og:title"');
        this.meta.updateTag({ content: title ? title : '' }, 'property="twitter:title"');

    }

    updateMetaTagDescription(description) {
        this.meta.updateTag({ content: description ? description : '' }, 'name="description"');
        this.meta.updateTag({ content: description ? description : '' }, 'property="og:description"');
        this.meta.updateTag({ content: description ? description : '' }, 'property="twitter:description"');

    }

    updateMetaTagUrl(URL) {
        let url = URL ? URL : window.location.href;
        this.meta.updateTag({ content: url ? url : '' }, 'property="url"');
        this.meta.updateTag({ content: url ? url : '' }, 'property="og:url"');
        this.meta.updateTag({ content: url ? url : '' }, 'property="twitter:url"');
    }

    updateMetaTagImage(image) {
        if (image && image.length > 0)
        {
          this.meta.updateTag({ content: image }, 'property="image"');
          this.meta.updateTag({ content: image }, 'property="og:image"');
          this.meta.updateTag({ content: image }, 'property="twitter:image"');
        }
        else {
          this.meta.removeTag('property="image"');
          this.meta.removeTag('property="og:image"');
          this.meta.removeTag('property="twitter:image"');
        }

    }

    titleCase = (str) => {
        let splitStr = str.toLowerCase().split(' ');
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    setRatingSeo() {
        setTimeout(() => {
            const rating = document.querySelectorAll('p-rating a');
            for (let i = 0; i <= rating.length; i++) {
                if (rating[i]) {
                    rating[i].setAttribute("href", "#");
                }
            }
        }, 0)
    }

    setngxImageSeo() {

        setTimeout(() => {
            const rating = document.querySelectorAll('#lib-ngx-image img');
            for (let i = 0; i <= rating.length; i++) {
                if (rating[i]) {
                    rating[i].setAttribute("alt", "ngx-img-lib");
                }
            }
        }, 0)
    }

    insertSchema (schema: Record<string, any>, className) {
        let script;
        let shouldAppend = false;
        if (this._document.head.getElementsByClassName(className).length) {
          script = this._document.head.getElementsByClassName(className)[0];
        } else {
          script = this._document.createElement('script');
          shouldAppend = true;
        }
        script.className = className;
        script.type = AppConstants.SCRIPT_TYPE;
        script.text = JSON.stringify(schema);
        if (shouldAppend) {
          this._document.head.appendChild(script);
        }
    }
    
    removeSchema (className) {
        let script;
        if (this._document.head.getElementsByClassName(className).length) {
        script = this._document.head.getElementsByClassName(className)[0];
          this._document.head.removeChild(script);
        }
    }


}
