import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConstants } from "../../app.constants";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class CheckoutDesktopService {

  constructor(private http: HttpClient) { }

  // Observable string sources
  private placeOrderTriggerCallSource = new Subject<any>();
  private userLoggedEventSource = new Subject<any>();

  // Observable string streams
  placeOrderTrigger = this.placeOrderTriggerCallSource.asObservable();
  userLoggedTrigger = this.userLoggedEventSource.asObservable();


  public orderDataMobile:BehaviorSubject<any> = new BehaviorSubject<any>(null);

  // orderData for thnank you page use
  private orderData = new BehaviorSubject<any>(null);


  setOrderData(data: any) {
    this.orderData.next(data);
  }

  getOrderData() {
    return this.orderData.asObservable();
  }

  getUserCheckout(isLogged) {
    if (isLogged) {
      const params = "?user_id=27";
      let headers = new HttpHeaders().set("Auth", "Bearer " + localStorage.getItem("access_token"));
      return this.http.get(AppConstants.baseURL + AppConstants.URL_USER_CHECKOUT_DETAILS, { headers: headers });
    } else {
      const params = "?session_id=" + (localStorage.getItem("session_id") === "undefined" ? null : localStorage.getItem("session_id"));
      return this.http.get(AppConstants.baseURL + AppConstants.URL_USER_CHECKOUT_DETAILS + params);
    }
  }

  getUserCards(isLogged) {
    if (isLogged) {
      let headers = new HttpHeaders().set("Auth", "Bearer " + localStorage.getItem("access_token"));
      return this.http.get(AppConstants.baseURL + AppConstants.URL_USER_CARDS, { headers: headers });
    }
  }

  addCardApi(token) {
    let stripe_card_token = token;
    let headers = new HttpHeaders().set("Auth", "Bearer " + localStorage.getItem("access_token"));
    return this.http.post(AppConstants.baseURL + AppConstants.URL_USER_CARDS, stripe_card_token, { headers: headers });
  }

  submitAddress(address, addressId?) {
    if (!address["session_id"]) {
      let headers = new HttpHeaders().set("Auth", "Bearer " + localStorage.getItem("access_token"));
      if (addressId) {
        return this.http.put(AppConstants.baseURL + AppConstants.URL_SUBMIT_ADDRESS + "/" + addressId, address, { headers: headers });
      } else {
        return this.http.post(AppConstants.baseURL + AppConstants.URL_SUBMIT_ADDRESS, address, { headers: headers });
      }

    } else {
      return this.http.post(AppConstants.baseURL + AppConstants.URL_SUBMIT_ADDRESS, address);
    }
  }

  getShippingDetails(isLogged, address_id?, data?) {
    let param = "?";
    if (address_id) {
      param += "address_id=" + address_id;
    }
    if (!isLogged) {
      if (!address_id) {
        param += "session_id=" + (localStorage.getItem("session_id") === "undefined" ? null : localStorage.getItem("session_id"));
      } else {
        param += "&session_id=" + (localStorage.getItem("session_id") === "undefined" ? null : localStorage.getItem("session_id"));
      }
    }
    if (data) {
      if (isLogged && !address_id) {
        if (data["zip_code"]) {
          param += "&zip_code=" + data["zip_code"];
        }
        if (data["state"]) {
          param += "&state=" + data["state"];
        }
      } else {
        if (data["zip_code"]) {
          param += "&zip_code=" + data["zip_code"];
        }
        if (data["state"]) {
          param += "&state=" + data["state"];
        }
      }
    }
    if (isLogged) {
      let headers = new HttpHeaders().set("Auth", "Bearer " + localStorage.getItem("access_token"));
      return this.http.get(AppConstants.baseURL + AppConstants.URL_GET_SHIPPING_DETAILS + param, { headers: headers });
    } else {
      return this.http.get(AppConstants.baseURL + AppConstants.URL_GET_SHIPPING_DETAILS + param);
    }
  }

  placeOrder(orderDetails, isLogged) {
    let headers;
    if (isLogged) {
      headers = new HttpHeaders().set("Auth", "Bearer " + localStorage.getItem("access_token"));
    }
    return this.http.post(AppConstants.baseURL + AppConstants.URL_PLACE_ORDER, orderDetails, { headers: headers });
  }

  addAfterpayToken(afterPayData, isLogged) {
    let headers;
    if (isLogged) {
      headers = new HttpHeaders().set("Auth", "Bearer " + localStorage.getItem("access_token"));
    }
    return this.http.post(AppConstants.baseURL + AppConstants.ADD_AFTERPAY_TOKEN, afterPayData, { headers: headers });
  }

  removeFromCart(itemId) {
    let url = AppConstants.baseURL + AppConstants.URL_CART + `/${itemId}`;
    let headers = { "Content-Type": "application/json" };
    let accessToken = localStorage.getItem("access_token");
    let sessionId = localStorage.getItem("session_id") === "undefined" ? null : localStorage.getItem("session_id");

    if (accessToken) {
      headers["Auth"] = `Bearer ${accessToken}`;
    } else {
      url += `?session_id=${sessionId}`;
    }

    return this.http.delete(url, { headers: new HttpHeaders(headers) });
  }

  deleteOrderAndAddresses(afterpay_client_secret, deleteType) {
    let stripe_card_token ={
      afterpay_client_secret: afterpay_client_secret,
      delete_type: deleteType
    };
    let headers = new HttpHeaders().set("Auth", "Bearer " + localStorage.getItem("access_token"));
    return this.http.post(AppConstants.baseURL + AppConstants.DELETE_ORDER_AND_ADDRESSES, stripe_card_token, { headers: headers });
  }

  updateItemQuantity(itemId, data) {
    let headers = { "Content-Type": "application/json" };
    let accessToken = localStorage.getItem("access_token");
    let sessionId = localStorage.getItem("session_id") === "undefined" ? null : localStorage.getItem("session_id");

    if (accessToken) {
      headers["Auth"] = `Bearer ${accessToken}`;
      data["session_id"] = null;
    } else {
      data["session_id"] = sessionId;
    }

    return this.http.put(AppConstants.baseURL + AppConstants.URL_CART + `/${itemId}`, data, {
      headers: new HttpHeaders(headers)
    });
  }

  setUserPassword(data) {
    return this.http.patch(AppConstants.baseURL + AppConstants.URL_FORGOT_PASSWORD, data);
  }
  broadcastSuccess() {
    this.placeOrderTriggerCallSource.next();
  }

  triggerLoginEvent() {
    this.userLoggedEventSource.next();
  }
  getGoogleAddressResponse(zipcode) {
    return this.http.get(AppConstants.baseURL + `/common_content/get_address_from_zip?zip=${zipcode}`);
    // return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&region=us&sensor=true&key=${AppConstants.GOOGLE_API_KEY}`);
  }
}
