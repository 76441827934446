import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CLOUDINARY_CONFIG, NgxPictureModule } from 'ngx-picture';
// import { FooterComponent } from '../footer/footer.component';
import { FooterNewDesignComponent } from 'src/app/footer-new-design/footer-new-design.component';

@NgModule({
  declarations: [
    // FooterComponent,
    FooterNewDesignComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPictureModule.forRoot(CLOUDINARY_CONFIG)
  ],
  exports: [
    // FooterComponent,
    FooterNewDesignComponent
  ]
})
export class FooterModule { }
