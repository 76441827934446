import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from 'rxjs';
import { AppConstants } from "src/app/app.constants";


@Injectable({
  providedIn: "root"
})
export class LoginSignupService {
  constructor(private httpClient: HttpClient) { }

  // Observable string sources
  private cartMergeTriggerCallSource = new Subject<any>();
  private cartResetTriggerCallSource = new Subject<any>();

  // Observable string streams
  cartMergeTrigger$ = this.cartMergeTriggerCallSource.asObservable();
  cartResetTrigger$ = this.cartResetTriggerCallSource.asObservable();

  userLoggedInTrigger: EventEmitter<any> = new EventEmitter<any>();
  userLoggedOutTrigger: EventEmitter<any> = new EventEmitter<any>();

  performLogoutTrigger: EventEmitter<any> = new EventEmitter<any>();
  performLoginTrigger: EventEmitter<any> = new EventEmitter<any>();

  // Add Vehicle
  private addVehicleMergeTriggerCallSource = new Subject<any>();
  addVehicleMergeTrigger$ = this.addVehicleMergeTriggerCallSource.asObservable();


  login(email, password?, fbAuthToken?) {
    let data = {
      user: {
        email: email,
        password: password,
        fb_token: fbAuthToken
      }
    };
    return this.httpClient.post(AppConstants.baseURL + AppConstants.URL_USER_LOGIN, data);
  }

  signup(email, password) {
    let data = {
      sign_up: {
        email: email,
        password: password
      }
    };
    return this.httpClient.post(AppConstants.baseURL + AppConstants.URL_USER_SIGN_UP, data);
  }

  logout() {
    return this.httpClient.delete(AppConstants.baseURL + AppConstants.URL_USER_LOGOUT);
  }

  forgotPassword(email: string) {
    let data = {
      email: email
    };
    return this.httpClient.post(AppConstants.baseURL + AppConstants.URL_FORGOT_PASSWORD, data);
  }

  verifyToken(email: string, token: string) {
    let params = "?token=" + token + "&email=" + email;
    return this.httpClient.get(AppConstants.baseURL + AppConstants.URL_FORGOT_PASSWORD + params);
  }

  resetPassword(email: string, password: string, token: string) {
    let data = {
      email: email,
      password: password,
      token: token
    };
    return this.httpClient.patch(AppConstants.baseURL + AppConstants.URL_FORGOT_PASSWORD, data);
  }

  checkAlreadyRegistered(faceBookId: string) {
    let params = "?fb_id=" + faceBookId;
    return this.httpClient.get(AppConstants.baseURL + AppConstants.URL_CHECK_REGISTERED + params);
  }

  checkTokenValid() {
    let headers = { "Content-Type": "application/json" };
    let token = localStorage.getItem("access_token");
    if (token) {
      headers["Auth"] = `Bearer ${token}`;
    }
    return this.httpClient.get(AppConstants.baseURL + AppConstants.URL_VALID_TOKEN, { headers: headers });
  }

  triggerAddVehicleMerge() {
    this.addVehicleMergeTriggerCallSource.next();
  }

  triggerCartMerge() {
    this.cartMergeTriggerCallSource.next();
  }

  resetCartTrigger() {
    this.cartResetTriggerCallSource.next();
  }

  emitUserLoggedInSignal(userData) {
    this.userLoggedInTrigger.emit(userData);
  }

  emitUserLoggedOutSignal() {
    this.userLoggedOutTrigger.emit();
  }

  performLogout() {
    this.performLogoutTrigger.emit();
  }

  getAuthInfo() {
    let user = localStorage.getItem("user");
    if (user) {
      return user
    }
    return null;
  }

  // name ambigous - should have been userHasBeenLoggedInWithData()
  performLogin(responseData) {
    // should be named triggerForUserHasBeenLoggedInWithData()
    this.performLoginTrigger.emit(responseData)
  }



}
