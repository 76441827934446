import { Component, Inject, OnDestroy, PLATFORM_ID } from "@angular/core"; 
import { Dropdown } from "primeng/dropdown";
import { isPlatformBrowser } from "@angular/common";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { DataService } from "./shared/common-services/data.service";
let domhandler_1 = require("primeng/dom");

declare let $: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnDestroy {
  title = "autobuffy-website";
  public isBrowser : any = false;
  routerUrl = '';
  overlay: any;

  constructor(router: Router, route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: Object, private dataService: DataService) {
    router.events.forEach(e => {
      if (e instanceof NavigationEnd) {
        this.routerUrl = e.url;
      }
    });
    this.isBrowser = this.dataService.getIsBrowser();
  }

  ngOnDestroy() {
    // alert("CLEARING STORAGE");
    // localStorage.clear();
  }
}

Dropdown.prototype.onOverlayAnimationStart = function (event) {
  switch (event.toState) {
    case 'visible':
      this.overlay = event.element;
      var itemsWrapperSelector = this.virtualScroll ? '.cdk-virtual-scroll-viewport' : '.ui-dropdown-items-wrapper';
      this.itemsWrapper = domhandler_1.DomHandler.findSingle(this.overlay, itemsWrapperSelector);
      this.appendOverlay();
      if (this.autoZIndex) {
        this.overlay.style.zIndex = String(this.baseZIndex + (++domhandler_1.DomHandler.zindex));
      }
      this.alignOverlay();
      this.bindDocumentClickListener();
      // this.bindDocumentResizeListener();
      if (this.options && this.options.length) {
        if (!this.virtualScroll) {
          var selectedListItem = domhandler_1.DomHandler.findSingle(this.itemsWrapper, '.ui-dropdown-item.ui-state-highlight');
          if (selectedListItem) {
            domhandler_1.DomHandler.scrollInView(this.itemsWrapper, selectedListItem);
          }
        }
      }
      if (this.filterViewChild && this.filterViewChild.nativeElement) {
        this.filterViewChild.nativeElement.focus();
      }
      this.onShow.emit(event);
      break;
    case 'void':
      this.onOverlayHide();
      break;
  }
};

