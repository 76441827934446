import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { PopupComponent } from '../popup/popup.component';

@NgModule({
  declarations: [
    PopupComponent
  ],
  imports: [
    CommonModule, 
    DialogModule 
  ],
  exports: [
    PopupComponent
  ]
})
export class PopupModule { }
