import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AppConstants } from "src/app/app.constants";
import { LoginSignupService } from "../core/login-signup.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CheckoutDesktopService } from "src/app/checkout/core/checkout-desktop.service";
import { DataService } from "src/app/shared/common-services/data.service";
import { Subscription } from "rxjs/internal/Subscription";

declare let $: any;

@Component({
  selector: "app-login-signup",
  templateUrl: "./login-signup.component.html",
  styleUrls: ["./login-signup.component.scss"],
  exportAs: "userAccess"
})
export class LoginSignupComponent implements OnInit, OnDestroy {
  @ViewChild("alertPopup", { static: true }) alertPopup: any;
  currentURL: string;
  constructor(private authService: LoginSignupService,
    private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute,
    private router: Router, private checkoutDesktopService: CheckoutDesktopService,
    private sharedService: DataService) {
    this.loginForm = this.formBuilder.group({
      loginEmail: new FormControl("", [Validators.email, Validators.required]),
      loginPassword: new FormControl("", [Validators.required, Validators.minLength(6), Validators.maxLength(16)])
    });
    this.signupForm = this.formBuilder.group(
      {
        signupEmail: new FormControl("", [Validators.email, Validators.required]),
        password: new FormControl("", [Validators.required, Validators.minLength(6), Validators.maxLength(16)]),
        confirmPassword: new FormControl("", [Validators.required, Validators.minLength(6), Validators.maxLength(16)])
      },
      { validator: this.checkPasswords }
    );
    this.fbLoginForm = this.formBuilder.group({
      fbLoginEmail: new FormControl("", [Validators.email, Validators.required])
    });
    this.forgotPasswordForm = this.formBuilder.group({
      forgotPasswordEmail: new FormControl("", [Validators.email, Validators.required])
    });
    this.resetPasswordForm = this.formBuilder.group(
      {
        password: new FormControl("", [Validators.required, Validators.minLength(6), Validators.maxLength(16)]),
        confirmPassword: new FormControl("", [Validators.required, Validators.minLength(6), Validators.maxLength(16)])
      },
      { validator: this.checkPasswords }
    );

    this.orderStatusForm = this.formBuilder.group({
      trackNumber: new FormControl("", [Validators.required])
    });

    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(params => {
      this.loginToSeeOrders = params['order'];
      this.verificationToken = params["token"];
      this.passwordResetEmail = params["email"] ? decodeURIComponent(params["email"]) : "";
      if (this.loginToSeeOrders) {
        // redirected from paypal
        if (this.verificationToken && this.passwordResetEmail) {
          this.showResetPasswordPopup();
        } else {
          this.sharedService.performLogin(["login_to_see_orders"]);
        }
      } else if (this.verificationToken) {
        // forgot password redirection
        this.resetPasswordIfvalidToken();
      }
    });
    this.performLogoutTriggerSubscription = this.authService.performLogoutTrigger.subscribe(() => {
      this.logout();
    });
    this.performLoginTriggerSubscription = this.authService.performLoginTrigger.subscribe(
      (userData) => {
        let accessToken = userData["token"];
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("user", JSON.stringify(userData));
        this.firstName = userData["first_name"];
        this.lastName = userData["last_name"];
        this._email = userData["email"];
        this._isLoggedIn = true;
        this.authService.triggerCartMerge();
        this.authService.triggerAddVehicleMerge();
        this.authService.emitUserLoggedInSignal(userData);
      }
    )

    this.initiateLoginPopupSubscription = this.sharedService.initiateLoginPopup.subscribe((x) => {
      if (x) {
        this.showLoginForm();
      }
    })
  }

  public _isLoggedIn: boolean = false;
  public _email: string;
  public firstName: string;
  public lastName: string;
  public get fullName(): string {
    if (this.firstName && this.lastName) return this.firstName + this.lastName;
    return "";
  }
  public get email(): string {
    return this._email;
  }
  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  emailErrorMessages = {
    email: AppConstants.INVALID_EMAIL,
    required: AppConstants.EMAIL_REQUIRED,
    pattern: AppConstants.INVALID_EMAIL
  };

  passwordErrorMessages = {
    required: AppConstants.PASSWORD_REQUIRED,
    minlength: AppConstants.PASSWORD_TOO_SHORT,
    maxlength: AppConstants.PASSWORD_TOO_LONG,
    notSame: AppConstants.PASSWORDS_MUST_MATCH
  };

  loginForm: any;
  loginFailed: boolean = false;
  loginErrorMessage: string;

  signupForm: any;
  signupFailed: boolean = false;
  signupErrorMessage: string;

  fbLoginForm: any;
  fbAuthToken: string;
  fbLoginFailed: boolean;
  fbLoginErrorMessage: string;

  forgotPasswordForm: any;
  forgotPasswordFailed: boolean;
  forgotPasswordErrorMessage: string;

  resetPasswordForm: any;
  resetPasswordFailed: boolean;
  resetPasswordErrorMessage: string;
  verificationToken: string;
  passwordResetEmail: string;
  loginToSeeOrders: boolean = false;

  orderStatusForm: any;
  orderNumberError: boolean = false;
  alertMessage: string;
  submittedTrackingNumber: boolean = false;

  isLoginToViewOrder: boolean = false;
  modalMessage: string;

  fbAuthstateSubscription: any = null;
  performLogoutTriggerSubscription: any;
  performLoginTriggerSubscription: any;
  queryParamsSubscription: any;
  initiateLoginPopupSubscription: Subscription;

  ngOnInit() {


  }



  ngOnDestroy() {
    if (this.fbAuthstateSubscription) {
      this.fbAuthstateSubscription.unsubscribe();
    }
    this.performLogoutTriggerSubscription.unsubscribe();
    this.performLoginTriggerSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
    if (this.initiateLoginPopupSubscription) {
      this.initiateLoginPopupSubscription.unsubscribe();
    }
  }

  showLoginForm(action?, email?) {
    this.hideAllPopUps();
    if (action === "login_to_see_orders") {
      this.isLoginToViewOrder = true;
      this.modalMessage = AppConstants.USER_LOGIN_PROMPT_TO_SEE_ORDERS;
    }
    if (email) {
      this.loginForm.patchValue({ loginEmail: email });
    }
    $("#loginModal").modal("show");
    let self = this;
    $("#loginModal").on("shown.bs.modal", function () {
      $("#loginEmail").trigger("focus");
    });
    $("#loginModal").on("hidden.bs.modal", function () {
      self.loginForm.reset();
      self.loginFailed = false;
      self.loginForm.submitted = false;
      if (self.isLoginToViewOrder) {
        self.router.navigate(["/"])
      }
    });
  }

  showOrderStatusForm() {
    this.hideAllPopUps();
    $("#orderStatus").modal("show");
  }

  showSignupForm(event?) {
    if (event) event.preventDefault(); // handles link clicks
    this.hideAllPopUps();
    $("#signupModal").modal("show");
    let self = this;
    $("#signupModal").on("shown.bs.modal", function () {
      $("#signupEmail").trigger("focus");
    });
    $("#signupModal").on("hidden.bs.modal", function () {
      self.signupForm.reset();
      self.signupFailed = false;
      self.signupForm.submitted = false;
    });
  }

  showEmailPopup() {
    this.hideAllPopUps();
    $("#collectEmailModal").modal("show");
    let self = this;
    $("#collectEmailModal").on("shown.bs.modal", function () {
      $("#fbLoginEmail").trigger("focus");
    });
    $("#collectEmailModal").on("hidden.bs.modal", function () {
      self.fbLoginForm.reset();
      self.fbLoginFailed = false;
      self.fbLoginForm.submitted = false;
    });
  }

  showForgotPasswordPopup() {
    this.hideAllPopUps();
    $("#forgotPasswordModal").modal("show");
    let self = this;
    $("#forgotPasswordModal").on("shown.bs.modal", function () {
      $("#forgotPasswordEmail").trigger("focus");
    });
    $("#forgotPasswordModal").on("hidden.bs.modal", function () {
      self.forgotPasswordForm.reset();
      self.forgotPasswordFailed = false;
      self.forgotPasswordForm.submitted = false;
    });
  }

  showResetPasswordPopup() {
    this.hideAllPopUps();
    $("#resetPasswordModal").modal("show");
    let self = this;
    $("#resetPasswordModal").on("shown.bs.modal", function () {
      $("#resetPasswordModal").trigger("focus");
    });
    $("#resetPasswordModal").on("hidden.bs.modal", function () {
      self.resetPasswordForm.reset();
      self.resetPasswordFailed = false;
      self.resetPasswordForm.submitted = false;
    });
  }

  hideAllPopUps() {
    this.isLoginToViewOrder = false;
    this.modalMessage = null;
    $("#loginModal,#signupModal,#collectEmailModal,#forgotPasswordModal,#resetPasswordModal, #orderStatus").modal("hide");
    if (this.alertPopup) {
      this.alertPopup.closePopup();
    }
  }

  // Validation helpers

  checkPasswords(group: FormGroup) {
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  get loginEmailErrors(): string {
    if (this.loginForm.controls.loginEmail.errors) {
      let validation_error = Object.keys(this.loginForm.controls.loginEmail.errors)[0];
      return this.emailErrorMessages[validation_error];
    }
    return "";
  }
  get loginPasswordErrors(): string {
    if (this.loginForm.controls.loginPassword.errors) {
      let validation_error = Object.keys(this.loginForm.controls.loginPassword.errors)[0];
      return this.passwordErrorMessages[validation_error];
    }
    return "";
  }

  get signupEmailErrors(): string {
    if (this.signupForm.controls.signupEmail.errors) {
      let validation_error = Object.keys(this.signupForm.controls.signupEmail.errors)[0];
      return this.emailErrorMessages[validation_error];
    }
    return "";
  }
  get signupPasswordErrors(): string {
    if (this.signupForm.controls.password.errors) {
      let validation_error = Object.keys(this.signupForm.controls.password.errors)[0];
      return this.passwordErrorMessages[validation_error];
    }
    return "";
  }
  get signupConfirmPasswordErrors(): string {
    if (this.signupForm.controls.confirmPassword.errors) {
      let validation_error = Object.keys(this.signupForm.controls.confirmPassword.errors)[0];
      return this.passwordErrorMessages[validation_error];
    } else if (this.signupForm.errors) {
      let validation_error = Object.keys(this.signupForm.errors)[0];
      return this.passwordErrorMessages[validation_error];
    }
    return "";
  }

  get fbLoginEmailErrors(): string {
    if (this.fbLoginForm.controls.fbLoginEmail.errors) {
      let validation_error = Object.keys(this.fbLoginForm.controls.fbLoginEmail.errors)[0];
      return this.emailErrorMessages[validation_error];
    }
    return "";
  }

  get forgotPasswordEmailErrors(): string {
    if (this.forgotPasswordForm.controls.forgotPasswordEmail.errors) {
      let validation_error = Object.keys(this.forgotPasswordForm.controls.forgotPasswordEmail.errors)[0];
      return this.emailErrorMessages[validation_error];
    }
    return "";
  }

  get newPasswordErrors(): string {
    if (this.resetPasswordForm.controls.password.errors) {
      let validation_error = Object.keys(this.resetPasswordForm.controls.password.errors)[0];
      return this.passwordErrorMessages[validation_error];
    }
    return "";
  }

  get confirmNewPasswordErrors(): string {
    if (this.resetPasswordForm.controls.confirmPassword.errors) {
      let validation_error = Object.keys(this.resetPasswordForm.controls.confirmPassword.errors)[0];
      return this.passwordErrorMessages[validation_error];
    } else if (this.resetPasswordForm.errors) {
      let validation_error = Object.keys(this.resetPasswordForm.errors)[0];
      return this.passwordErrorMessages[validation_error];
    }
    return "";
  }

  getAuthInfo() {
    let user = localStorage.getItem("user");
    this._isLoggedIn = false;
    if (user) {
      let user_data = JSON.parse(user);
      this._isLoggedIn = true;
      this._email = user_data["email"];
      this.firstName = user_data["first_name"];
      this.lastName = user_data["last_name"];
    }
    return this._isLoggedIn;
  }

  logout() {
    this.authService.logout().subscribe(response => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      localStorage.removeItem("session_id");
      this.authService.resetCartTrigger();
      this.authService.emitUserLoggedOutSignal();
      if ($(window).width() < 767) this.checkoutDesktopService.triggerLoginEvent();
      else this.checkoutDesktopService.triggerLoginEvent();
      this._isLoggedIn = false;
      this._email = "";
      this.firstName = "";
      this.lastName = "";
      this.currentURL = window.location.href;
      if (this.currentURL.includes("dashboard")) {
        this.router.navigate([""]);
      }
    });
  }

  login() {
    this.loginForm.submitted = true;
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value.loginEmail, this.loginForm.value.loginPassword, null).subscribe(
        response => {
          if (response["status"] == 20) {
            localStorage.setItem("access_token", response["data"]["token"]);
            localStorage.setItem("user", JSON.stringify(response["data"]));
            this.firstName = response["data"]["first_name"];
            this.lastName = response["data"]["last_name"];
            this._email = response["data"]["email"];
            this._isLoggedIn = true;
            this.authService.triggerCartMerge();
            this.authService.triggerAddVehicleMerge();
            this.authService.emitUserLoggedInSignal(response["data"]);
            if (this.isLoginToViewOrder) {
              this.hideAllPopUps();
              this.router.navigate(["dashboard/orders"]);
            } else {
              window.setTimeout(() => {
                if ($(window).width() < 768) this.checkoutDesktopService.triggerLoginEvent();
                else this.checkoutDesktopService.triggerLoginEvent();
              }, 1500);
            }
            this.hideAllPopUps();
          } else {
            this.loginForm.controls["loginPassword"].reset();
            this.loginFailed = true;
            this.loginErrorMessage = response["message"];
          }
        },
        error => {
          if (error.status == 401) {
            this.loginForm.controls["loginPassword"].reset();
            this.loginFailed = true;
            this.loginErrorMessage = error["error"]["message"];
          } else {
            this.loginForm.controls["loginPassword"].reset();
            this.loginFailed = true;
            this.loginErrorMessage = AppConstants.GENERIC_MESSAGE;
          }
        }
      );
    }
  }
  signup() {
    this.signupForm.submitted = true;
    if (this.signupForm.valid) {
      this.authService.signup(this.signupForm.value.signupEmail, this.signupForm.value.password).subscribe(
        response => {
          if (response["status"] == 20) {
            localStorage.setItem("access_token", response["data"]["token"]);
            localStorage.setItem("user", JSON.stringify(response["data"]));
            this.firstName = response["data"]["first_name"];
            this.lastName = response["data"]["last_name"];
            this._email = response["data"]["email"];
            this._isLoggedIn = true;
            this.authService.triggerCartMerge();
            this.authService.triggerAddVehicleMerge();
            this.authService.emitUserLoggedInSignal(response["data"]);
            window.setTimeout(() => {
              if ($(window).width() < 768) this.checkoutDesktopService.triggerLoginEvent();
              else this.checkoutDesktopService.triggerLoginEvent();
            }, 1500);
            this.hideAllPopUps();
          } else {
            this.signupForm.reset();
            this.signupFailed = true;
            this.signupErrorMessage = response["message"];
          }
        },
        error => {
          if (error.status == 401) {
            this.signupForm.reset();
            this.signupFailed = true;
            this.signupErrorMessage = error["error"]["message"];
          } else {
            this.signupForm.reset();
            this.signupFailed = true;
            this.signupErrorMessage = AppConstants.GENERIC_MESSAGE;
          }
        }
      );
    }
  }

  loginWithFB() {
    this.fbLoginForm.submitted = true;
    if (this.fbAuthToken) {
      this.authService.login(this.fbLoginForm.value.fbLoginEmail, null, this.fbAuthToken).subscribe(
        response => {
          if (response["status"] == 20) {
            localStorage.setItem("access_token", response["data"]["token"]);
            localStorage.setItem("user", JSON.stringify(response["data"]));
            this.firstName = response["data"]["first_name"];
            this.lastName = response["data"]["last_name"];
            this._email = response["data"]["email"];
            this._isLoggedIn = true;
            this.authService.triggerCartMerge();
            this.authService.triggerAddVehicleMerge();
            this.authService.emitUserLoggedInSignal(response["data"]);
            if (this.isLoginToViewOrder) {
              this.hideAllPopUps();
              this.router.navigate(["dashboard/orders"]);
            } else {
              if ($(window).width() < 768) this.checkoutDesktopService.triggerLoginEvent();
              else this.checkoutDesktopService.triggerLoginEvent();
            }
            this.hideAllPopUps();
          } else {
            this.hideAllPopUps();
            this.alertPopup.showPopup("error", response["message"] ? response["message"] : AppConstants.FB_AUTHENTICATION_FAILED);
          }
        },
        error => {
          this.hideAllPopUps();
          this.alertPopup.showPopup("error", AppConstants.FB_AUTHENTICATION_FAILED);
        }
      );
    } else {
      this.hideAllPopUps();
      this.alertPopup.showPopup("error", AppConstants.FB_AUTHENTICATION_FAILED);
    }
  }

 
  forgotPassword() {
    this.forgotPasswordForm.submitted = true;
    if (this.forgotPasswordForm.valid) {
      this.authService.forgotPassword(this.forgotPasswordForm.value.forgotPasswordEmail).subscribe(
        response => {
          if (response["status"] == 20) {
            this.hideAllPopUps();
            this.alertPopup.showPopup("success", AppConstants.RESET_PASSWORD_MAIL_SENT);
          } else {
            this.hideAllPopUps();
            this.alertPopup.showPopup("error", response["message"]);
          }
        },
        error => {
          this.hideAllPopUps();
          this.alertPopup.showPopup("error", AppConstants.GENERIC_MESSAGE);
        }
      );
    }
  }

  resetPassword() {
    this.resetPasswordForm.submitted = true;
    if (this.resetPasswordForm.valid && this.verificationToken && this.passwordResetEmail) {
      this.authService.resetPassword(this.passwordResetEmail, this.resetPasswordForm.value.password, this.verificationToken).subscribe(
        response => {
          if (response["status"] == 20) {
            this.hideAllPopUps();
            if (this.loginToSeeOrders) {
              this.authService.performLogin(response["data"]);
              this.router.navigate(["dashboard/orders"]);
              return;
            }
            this.alertPopup.showPopup("success", AppConstants.RESET_PASSWORD_COMPLETE);
          } else {
            this.hideAllPopUps();
            this.alertPopup.showPopup("error", response["message"]);
          }
        },
        error => {
          this.hideAllPopUps();
          this.alertPopup.showPopup("error", AppConstants.GENERIC_MESSAGE);
        }
      );
    } else if (!this.resetPasswordForm.valid) {
    } else if (!(this.verificationToken && this.passwordResetEmail)) {
      this.hideAllPopUps();
    }
  }

  resetPasswordIfvalidToken() {
    if (this.verificationToken && this.passwordResetEmail) {
      this.authService.verifyToken(this.passwordResetEmail, this.verificationToken).subscribe(response => {
        if (response["status"] == "20") {
          this.hideAllPopUps();
          this.showResetPasswordPopup();
        } else {
          // login component is used twice so this callback will also be invoked twice.
          // To fix that, emitting a tigger to show pop-up from app.component.ts
          this.sharedService.emitEventToShowPopUpInHomePage(
            {
              type: "error",
              message: AppConstants.RESET_PASSWORD_LINK_EXPIRED
            }
          );
        }
      });
    }
  }



  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  trackOrder() {
    this.orderStatusForm.submitted = true;
    if (this.orderStatusForm.valid) {

      if (this.orderStatusForm.value.trackNumber) {
        this.orderNumberError = false;
        this.alertMessage = "";
        this.router.navigate(["track-order"], { queryParams: { tracking_number: this.orderStatusForm.value.trackNumber } });
        this.hideAllPopUps();
        this.orderStatusForm.controls["trackNumber"].reset();

      } else {
        this.orderStatusForm.controls["trackNumber"].reset();
        this.orderNumberError = true;
        this.alertMessage = "Please enter tracking number";
      }
    }
    else {
      this.orderStatusForm.controls["trackNumber"].reset();
      this.orderNumberError = true;
      this.alertMessage = "Please enter tracking number";
    }
  }
}
