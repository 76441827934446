<div #userAccess>
  <div id="loginModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered login-popup" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title">Hello Again</p>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body form">
          <form [formGroup]="loginForm" (ngSubmit)="login()" #ngLoginForm="ngForm">
            <div *ngIf="!loginFailed" class="invalid-feedback error-response">
              {{ modalMessage }}
            </div>
            <div *ngIf="loginFailed" class="invalid-feedback error-response">
              {{ loginErrorMessage }}
            </div>
            <label for="loginEmail" class="login-label">Please log in</label>
            <div class="input-group email-container">
              <input (keyup.enter)="login()" type="email" id="loginEmail" class="form-control"
                placeholder="Email Address" aria-label="email" formControlName="loginEmail"
                [ngClass]="{'is-invalid': loginForm.controls.loginEmail.invalid && loginForm.submitted && !loginFailed }">
              <div class="invalid-feedback">
                {{ loginEmailErrors }}
              </div>
            </div>
            <div class="input-group password-container">
              <input (keyup.enter)="login()" type="password" id="loginPassword" class="form-control"
                placeholder="Password" aria-label="password" formControlName="loginPassword"
                [ngClass]="{'is-invalid': loginForm.controls.loginPassword.invalid && loginForm.submitted && !loginFailed }">
              <div class="invalid-feedback">
                {{ loginPasswordErrors }}
              </div>
            </div>
            <div class="forgot-password-container">
              <span class="forgot-class" *ngIf="!isLoginToViewOrder" (click)="showForgotPasswordPopup()">Forgot Password?</span>
            </div>
            <div class="login-button-container">
              <button class="btn btn-primary btn-block login-button" type="submit">Login</button>
            </div>
            
            <div *ngIf="!isLoginToViewOrder" class="create-account-container">
              <span class="forgot-class" (click)="showSignupForm($event)">Create New Account</span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div id="signupModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered signup-popup" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title">Create Account</p>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body form">
          <form [formGroup]="signupForm" (ngSubmit)="signup()" #ngSignupForm="ngForm">
            <div *ngIf="signupFailed" class="invalid-feedback error-response">
              {{ signupErrorMessage }}
            </div>
            <div class="input-group email-container">
              <input (keyup.enter)="signup()" type="email" id="signupEmail" class="form-control"
                placeholder="Email Address" aria-label="email" formControlName="signupEmail"
                [ngClass]="{'is-invalid': signupForm.controls.signupEmail.invalid && signupForm.submitted && !signupFailed }">
              <div class="invalid-feedback">
                {{ signupEmailErrors }}
              </div>
            </div>
            <div class="input-group password-container">
              <input (keyup.enter)="signup()" type="password" id="signupPassword" class="form-control"
                placeholder="Password" aria-label="password" formControlName="password"
                [ngClass]="{'is-invalid': signupForm.controls.password.invalid && signupForm.submitted && !signupFailed }">
              <div class="invalid-feedback">
                {{ signupPasswordErrors }}
              </div>
            </div>
            <div class="input-group confirm-password-container">
              <input (keyup.enter)="signup()" type="password" id="signupConfirmPassword" class="form-control"
                placeholder="Confirm Password" aria-label="confirm_password" formControlName="confirmPassword"
                [ngClass]="{'is-invalid': (signupForm.controls.confirmPassword.invalid || ngSignupForm.hasError('notSame')) && signupForm.submitted && !signupFailed }">
              <div class="invalid-feedback">
                {{ signupConfirmPasswordErrors }}
              </div>
            </div>
            <div class="signup-button-container">
              <button class="btn btn-primary btn-block signup-button" type="submit">Sign Up</button>
            </div>
           
          </form>
        </div>
      </div>
    </div>
  </div>

  <div id="collectEmailModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered collect-email-popup" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title">Email Address</p>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body form">
          <form [formGroup]="fbLoginForm" (ngSubmit)="loginWithFB()" #ngFbLoginForm="ngForm">
            <div *ngIf="fbLoginFailed" class="invalid-feedback error-response">
              {{ fbLoginErrorMessage }}
            </div>
            <div class="input-group email-container">
              <input (keyup.enter)="loginWithFB()" type="email" id="fbLoginEmail" class="form-control"
                placeholder="Email Address" aria-label="email" formControlName="fbLoginEmail"
                [ngClass]="{'is-invalid': fbLoginForm.controls.fbLoginEmail.invalid && fbLoginForm.submitted }">
              <div class="invalid-feedback">
                {{ fbLoginEmailErrors }}
              </div>
            </div>
            <div class="signup-button-container">
              <button class="btn btn-primary btn-block signup-button" type="submit">Sign Up</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div id="forgotPasswordModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered collect-email-popup" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title">Forgot Password</p>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body form">
          <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()" #ngForgotPasswordForm="ngForm">
            <div *ngIf="forgotPasswordFailed" class="invalid-feedback error-response">
              {{ forgotPasswordErrorMessage }}
            </div>
            <div class="input-group email-container">
              <input (keyup.enter)="forgotPassword()" type="email" id="forgotPasswordEmail" class="form-control"
                placeholder="Email Address" aria-label="email" formControlName="forgotPasswordEmail"
                [ngClass]="{'is-invalid': forgotPasswordForm.controls.forgotPasswordEmail.invalid && forgotPasswordForm.submitted }">
              <div class="invalid-feedback">
                {{ forgotPasswordEmailErrors }}
              </div>
            </div>
            <div class="send-button-container">
              <button class="btn btn-primary btn-block send-button" type="submit">Send</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div id="resetPasswordModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered reset-password-popup" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title">{{ loginToSeeOrders? 'Create Account' : 'Reset Password' }}</p>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body form">
          <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()" #ngResetPasswordForm="ngForm">
            <div *ngIf="resetPasswordFailed" class="invalid-feedback error-response">
              {{ resetPasswordErrorMessage }}
            </div>
            <div class="input-group password-container">
              <input (keyup.enter)="resetPassword()" type="password" id="newPassword" class="form-control"
                placeholder="New Password" aria-label="password" formControlName="password"
                [ngClass]="{'is-invalid': resetPasswordForm.controls.password.invalid && resetPasswordForm.submitted && !resetPasswordFailed }">
              <div class="invalid-feedback">
                {{ newPasswordErrors }}
              </div>
            </div>
            <div class="input-group confirm-password-container">
              <input (keyup.enter)="resetPassword()" type="password" id="confirmNewPassword" class="form-control"
                placeholder="Confirm Password" aria-label="confirm_password" formControlName="confirmPassword"
                [ngClass]="{'is-invalid': (resetPasswordForm.controls.confirmPassword.invalid || ngResetPasswordForm.hasError('notSame')) && resetPasswordForm.submitted && !resetPasswordFailed }">
              <div class="invalid-feedback">
                {{ confirmNewPasswordErrors }}
              </div>
            </div>
            <div *ngIf="!loginToSeeOrders" class="reset-button-container">
              <button class="btn btn-primary btn-block reset-button" type="submit">Reset Password</button>
            </div>
            <div *ngIf="loginToSeeOrders" class="row set-password-button-container">
              <div class="col-xs-12 col-md-6 p-0 pr-md-2">
                <button type="reset" class="btn btn-primary btn-block reset-button" data-dismiss="modal"
                  aria-label="Close">Skip</button>
              </div>
              <div class="col-xs-12 col-md-6 mb-xs-2 p-0 pl-md-2 mb-3">
                <button type="submit" class="btn btn-primary btn-block reset-button">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


  <div id="orderStatus" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered order-status-popup" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <p class="order-status-title">Order Status</p>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body form">
          <form [formGroup]="orderStatusForm" #ngorderStatusForm="ngForm">
            <div *ngIf="loginFailed" class="invalid-feedback error-response">
              {{ alertMessage }}
            </div>
            <div class="input-group email-container track-number-container">
              <input id="trackNumber" class="form-control" type="text" placeholder="Enter Tracking Number"
                aria-label="email" formControlName="trackNumber" (keypress)="keyPress($event)"
                [ngClass]="{'is-invalid': orderStatusForm.controls.trackNumber.invalid && orderStatusForm.submitted && orderNumberError }">
              <div class="invalid-feedback">
                {{ alertMessage }}
              </div>
            </div>
            <div class="login-button-container traking">
              <button class="btn btn-primary btn-block login-button" type="submit" (click)="trackOrder()">Track</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<app-popup #alertPopup="alertPopup"></app-popup>
