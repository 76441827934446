import { Component } from "@angular/core";
import { DataService } from "src/app/shared/common-services/data.service";

@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.scss"],
  exportAs: 'alertPopup'
})
export class PopupComponent {
  popup: any = false;
  alert: any = {
    type: "",
    message: "",
    escclose: true,
  };


  constructor(private sharedService: DataService) { }


  action(btn: string) {
    switch (btn) {
      case "ok":
        this.sharedService.triggerPopupOk();
        this.closePopup();
    }
  }

  showPopup(type, message, esc?) {
    this.alert["type"] = type;
    this.alert["message"] = message;
    if (esc) {
      this.alert["escclose"] = false;
    }
    this.popup = true;
  }

  closePopup() {
    this.popup = false;
    this.alert["escclose"] = true;
    this.sharedService.triggerPopupClose();
  }


}
