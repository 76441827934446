<footer>
  <div class="base-container mx-auto container pt-5 px-3">
    <h2 class="common-styles-links">Contact & Help</h2>
    <div class="mb-5 link-section">
      <ul class="pages-links list-unstyled p-0 d-flex flex-wrap">
        <li *ngFor="let list of contactAndHelpLinks">
          <a [class]="list.classes" *ngIf="list.name != 'Order Tracking'" routerLink="{{list.link}}">{{list.name}}</a>
          <a [class]="list.classes" *ngIf="list.name == 'Order Tracking'" (click)="goToOrderStatus()">{{list.name}}</a>
        </li>
      </ul>
      <ul class="chat-box bg-white list-unstyled m-0 py-3 align-items-center">
        <li *ngFor="let list of chatAndTalkLinks" class="px-3">
          <p class="mb-1">{{list.text}}</p>
          <a *ngIf="list.name == 'Live Chat'" class="chat-action router-links" (click)="openChat()">
            {{list.name}}
            <i class="fi fi-rr-angle-right"></i>
          </a>
          <a *ngIf="list.name == 'Auto Talks'" href="https://www.blog.autobuffy.com" target="_blank" class="chat-action router-links">
            {{list.name}}
            <i class="fi fi-rr-angle-right"></i>
          </a>  
        </li>
      </ul>
    </div>
    <ul class="d-flex p-0 social-media-wrapper flex-wrap">
      <li *ngFor="let list of phoneAndMailLinks" [class]="list.liClass">
        <i class="{{list.icon}}"></i>
        <div class="ml-3 d-flex flex-column">
          <p class="mb-0">{{list.name}}</p>
          <a *ngIf="list.name == 'Mail'" [routerLink]="list.href"  [class]="list.aClasses">{{list.text}}</a>
          <a *ngIf="list.name != 'Mail'" [href]="list.href" [class]="list.aClasses">{{list.text}}</a>
        </div>
      </li>
      <li class="d-flex align-items-center social-media-link">
        <div *ngFor="let icon of socialIcons">
          <a [href]="icon.link" target="_blank" class="social-link d-flex align-items-center justify-content-center text-white p-2">
            <img class="social-icons" [src]="icon.isActive ? icon.active : icon.inActive" alt="" (mouseenter)="icon.isActive = true" (mouseleave)="icon.isActive = false" loading="lazy">
          </a>
        </div>
      </li>
    </ul>
  </div>
  <div class="footer-copyright-block py-3 desktop">
    <div class="base-container mx-auto container d-flex align-items-center justify-content-between">
      <ng-template [ngTemplateOutlet]="policyTemplate"></ng-template>
    </div>
  </div>
  <div class="footer-copyright-block py-3 mobile">
    <ng-template [ngTemplateOutlet]="policyTemplate"></ng-template>
  </div>
</footer>


<ng-template #policyTemplate>
  <ul class="m-0 d-flex list-unstyled" [ngClass]="isMobile ? 'px-3 justify-content-between' : 'p-0'">
    <li *ngFor="let list of termsAndPolicyLinks" [class]="list.liClass">
      <a class="router-links footer-copyright-common-font" [routerLink]="list.link">{{list.name}}</a>
    </li>
  </ul>
  <p class="mb-0 footer-copyright-common-font" [ngClass]="isMobile ? 'px-3' : ''">Copyright © 2023 autobuffy.com</p>
</ng-template>