import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopupModule } from 'src/app/shared/components/popup/core/popup.module';
import { LoginSignupComponent } from '../login-signup/login-signup.component';
  
@NgModule({
    declarations: [
        LoginSignupComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PopupModule
    ],
    exports: [
        LoginSignupComponent
    ],
    providers:[ { provide: 'SocialAuthServiceConfig', useValue: {
      autoLogin: false
    } },]
})
export class LoginSignUpModule { }
