import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckoutDesktopService } from 'src/app/checkout/core/checkout-desktop.service';
import { DataService } from '../shared/common-services/data.service';
import { Subscription } from 'rxjs';


declare let $: any;

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss']
})
export class ThankYouPageComponent implements OnInit {

  isLogged: boolean = false;
  orderData:any = {};
  tempToken: any;
  userEmail: string;
  private orderDataSub: Subscription;

  constructor(
              private route: ActivatedRoute,
              private checkoutService: CheckoutDesktopService,
              private sharedService: DataService,
              private router: Router,
              ) {
                this.route.queryParams.subscribe(params => {
                  this.tempToken = params['token'];
                  const success = params['is_success'];
                  const pref = params['checkout_preference'];
      if (success && pref === 'paypal') {
        this.tempToken = params['ctoken'] ? params['ctoken'] : '';
        this.userEmail = params['cemail'] ? params['cemail'] : '';
      }
              })
               }

  ngOnInit(): void {
      this.getOrderDetail();
  }
  
  

  getOrderDetail() {
  this.orderDataSub = this.checkoutService.getOrderData().subscribe(data => {
    if (data) {
      this.orderData = data.response[0];
      this.sharedService.pushPurchaseDataToGoogleAnalytics(data);
    }
    else {
      const orderDataString = localStorage.getItem('orderData');
    if (orderDataString) {
      const orderData = JSON.parse(orderDataString);
      if (orderData && orderData.response.length > 0) {
        this.orderData = orderData.response[0];
        this.sharedService.pushPurchaseDataToGoogleAnalytics(orderData);
      } else {
        console.error('orderData or response array is not in the expected format.');
      }
      }
    }
  });
 }

 checkoutLogin(params?) {
  if (params) {
    this.sharedService.performLogin(params);
  } else {
    this.sharedService.performLogin();
  }
}

 hidePlaceOrderSuccessPopup() {
  $('body').removeClass('greenBg');
  if (!this.isLogged) {
    if (this.tempToken) {
      // There is password-reset-token, so this is a new user. Should show password-setting form.
      localStorage.removeItem("session_id");
    } else {
      this.checkoutLogin(["login_to_see_orders", this.userEmail]);
    }
  } else {
    localStorage.removeItem("session_id");
    this.router.navigate(["dashboard/orders"]);
  }
}

ngOnDestroy() {
  if(this.orderDataSub){
    this.orderDataSub.unsubscribe();
  }
  localStorage.removeItem("orderData");
}

}

