<div class="d-flex flex-column">

    <section class="thankyou row m-0">
        <div class="left d-flex flex-column col-12 col-lg-6">
            <div class="d-flex thankyou-container">
                <div class="mask">
                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M27 0C12.1117 0 0 12.1117 0 27C0 41.8882 12.1117 54 27 54C41.8882 54 54 41.8882 54 27C54 12.1117 41.8882 0 27 0ZM26.7952 34.6927C25.9245 35.5635 24.7792 35.9977 23.6295 35.9977C22.4797 35.9977 21.3233 35.559 20.4435 34.6815L14.184 28.6155L17.3183 25.3822L23.6025 31.473L36.6727 18.6458L39.8317 21.852L26.7952 34.6927Z"
                            fill="#007BFF" />
                    </svg>
                </div>
                <div class="pl-0">
                    <span class="light-text-2 font-weight-bold">#{{orderData?.order_number}}</span>
                    <h4 class="h4 font-weight-bold">Thank You {{orderData?.customer_name}}</h4>
                </div>
            </div>
            <div class="thankyou-container | bordered">
                <h4 class="h6 font-weight-bold mb-2">Your Order is Confirmed</h4>
                <p class="light-text-2">We’ve accepted your order, and we’re getting it ready. A confirmation email has
                    been
                    sent to your email.
                </p>
            </div>
            <div class="thankyou-container bordered p-4">
                <h4 class="mb-3 h5 font-weight-bold">Customer Information</h4>
                <div class="row bordered-b mb-5 pb-5">
                    <div class="col-6">
                        <strong class="d-block | mb-3  h6 font-weight-bold">Shipping Address</strong>
                        <ul>
                            <li class="light-text-2 mb-1">{{orderData?.shipping_address?.name}}</li>
                            <p>{{orderData?.shipping_address?.address}}</p>
                        </ul>
                    </div>
                    <div class="col-6">
                        <strong class="d-block | mb-3 h6 font-weight-bold">Billing Address</strong>
                        <ul>
                            <li class="light-text-2 mb-1">{{orderData?.billing_address?.name}}</li>
                            <p>{{orderData?.billing_address?.address}}</p>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <strong class="d-block mb-3">Shipping Method</strong>
                        <small class="light-text-2">{{orderData?.shipping_method}}</small>
                    </div>
                    <div class="col-6">
                        <strong class="d-block mb-3">Payment Method</strong>
                        <div class="d-flex">
                            <svg width="21" class="mr-1" height="15" viewBox="0 0 21 15" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="21" height="15" rx="4" fill="#1A346B" />
                                <g clip-path="url(#clip0_8043_850)">
                                    <path
                                        d="M7.72799 11.3996C7.30439 11.3942 6.89218 11.3204 6.49332 11.1843C5.80591 10.9498 5.21233 10.5699 4.71925 10.0408C4.37849 9.67517 4.11311 9.26357 3.9176 8.8056C3.81828 8.57217 3.7429 8.33256 3.68676 8.08715C3.65339 7.94145 3.63416 7.79188 3.61649 7.6427C3.6055 7.55034 3.60864 7.45836 3.59961 7.36638C3.59961 7.25546 3.59961 7.14415 3.59961 7.03324C3.60903 6.61198 3.6903 6.20348 3.83359 5.80734C3.98081 5.39961 4.18573 5.02126 4.45151 4.67575C4.76518 4.26802 5.1401 3.92638 5.57665 3.65005C5.99396 3.38609 6.44778 3.20522 6.92987 3.09739C7.1297 3.05295 7.33345 3.02357 7.53994 3.0143C7.60158 3.01159 7.66557 3.00773 7.72799 3C7.82025 3 7.9125 3 8.00476 3C8.67018 3.03246 9.30145 3.18937 9.89032 3.50048C10.1059 3.61411 10.3057 3.7513 10.502 3.89391C10.502 3.89855 10.502 3.90319 10.5012 3.90783C10.233 4.09952 10.0065 4.33333 9.80042 4.58686C9.55702 4.88638 9.36152 5.21488 9.20802 5.56773C9.02193 5.99556 8.91241 6.44309 8.88257 6.9057C8.85901 7.27092 8.87786 7.63614 8.95088 7.99903C9.04235 8.45159 9.20291 8.87633 9.43179 9.2771C9.59 9.55382 9.7804 9.8085 9.99789 10.0419C10.1514 10.2066 10.3139 10.3627 10.5008 10.4918C10.5012 10.4964 10.5016 10.5011 10.5012 10.5057C10.2279 10.7063 9.94293 10.8887 9.63043 11.0255C9.31244 11.1646 8.98385 11.2728 8.64192 11.3339C8.43189 11.3714 8.21911 11.4004 8.00397 11.3992C7.91172 11.3992 7.81946 11.3992 7.7272 11.3992L7.72799 11.3996Z"
                                        fill="#EB001B" />
                                    <path
                                        d="M10.5023 10.5057C10.5023 10.5011 10.5023 10.4964 10.502 10.4918C10.564 10.4439 10.6299 10.4002 10.6869 10.3472C10.9868 10.0698 11.26 9.77102 11.4795 9.42435C11.8862 8.78126 12.097 8.08135 12.1288 7.32773C12.1406 7.05218 12.1159 6.77701 12.0695 6.50493C11.9592 5.85527 11.7056 5.26435 11.3126 4.73179C11.0826 4.41991 10.8141 4.14242 10.502 3.90783C10.5023 3.90319 10.5027 3.89855 10.5027 3.89392C11.008 3.5028 11.5757 3.23807 12.2014 3.09894C12.4126 3.05218 12.6274 3.01082 12.8472 3.01623C12.8994 3.01739 12.9524 3.00541 13.005 2.99923C13.0973 2.99923 13.1892 3 13.2814 3.00039C15.6204 3.09817 17.2634 4.8972 17.3902 6.89449C17.4652 8.07401 17.1205 9.12329 16.3114 10.0048C15.503 10.8856 14.4866 11.3447 13.2814 11.3985C13.1892 11.3985 13.0973 11.3992 13.005 11.3996C12.5744 11.3927 12.1551 11.3185 11.75 11.1778C11.2981 11.0209 10.88 10.799 10.5031 10.5053L10.5023 10.5057Z"
                                        fill="#F79E1B" />
                                    <path
                                        d="M10.5008 3.90821C10.8129 4.1428 11.0815 4.42029 11.3115 4.73217C11.7041 5.26435 11.9577 5.85565 12.0684 6.50531C12.1147 6.77778 12.1391 7.05256 12.1277 7.32811C12.0959 8.08174 11.8851 8.78164 11.4784 9.42473C11.2593 9.7714 10.9861 10.0701 10.6857 10.3476C10.6284 10.4006 10.5625 10.4442 10.5008 10.4922C10.314 10.3631 10.1514 10.207 9.99794 10.0423C9.78006 9.80889 9.58966 9.5542 9.43184 9.27749C9.20297 8.87671 9.04201 8.45198 8.95093 7.99942C8.87791 7.63691 8.85907 7.27169 8.88262 6.90609C8.91246 6.44348 9.02199 5.99594 9.20807 5.56811C9.36157 5.21526 9.55708 4.88676 9.80048 4.58724C10.0066 4.3341 10.2331 4.0999 10.5012 3.90821H10.5008Z"
                                        fill="#FF5F00" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_8043_850">
                                        <rect width="13.8" height="8.4" fill="white" transform="translate(3.59961 3)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <div class="d-flex flex-column">
                                <span class="light-text-2 ending-with">Ending with ..... {{orderData.card_number}}</span>
                                <span class="light-text-1 font-weight-bold">${{orderData?.total}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right col-12 col-lg-6 d-flex flex-column">
            <!-- *ngIf="orderData && orderData.order_parts.length > 0" -->
            <div *ngFor="let item of orderData.order_parts" class="d-flex thankyou-container bordered-b">
                <div class="img mr-3">
                    <img class="product-image" [src]="item.image_url ? item.image_url:'./assets/images/part-placeholder.svg'" alt="product" />
                </div>
                <div class="w-100">
                    <div class="d-flex justify-content-between product-info">
                        <p class="light-text-1 font-weight-bold">{{item.title}}</p>
                        <span class="h4 font-weight-bold d-none d-lg-block">${{item.price}}</span>
                    </div>
                    <span class="light-text-2">x {{item.quantity}}</span>
                </div>
            </div>
            <div class="thankyou-container bordered-b">
                <h4 class="h6 font-weight-bold mb-4">ORDER SUMMARY</h4>
                <ul>
                    <li class="d-flex justify-content-between light-text-2 mb-3">
                        <span>Subtotal</span>
                        <span>${{orderData?.sub_total}}</span>
                    </li>
                </ul>
            </div>
            <div class="d-flex justify-content-between align-items-center thankyou-container bordered-b">
                <strong class="h6 font-weight-bold">Total</strong>
                <span class="h2 font-weight-bold">${{orderData?.total}}</span>
            </div>
            <div
                class="continue-shipping d-flex flex-column flex-lg-row justify-content-between align-items-center thankyou-container">
                <button class="btn btn-continue-shipping" routerLink="/">CONTINUE SHOPPING</button>
                <span class="light-text-1 font-weight-bold">
                    <!-- Need Help? <a routerLink="/contact" class="text-underline">Contact Us</a> -->
                    <a (click)="hidePlaceOrderSuccessPopup()" class="text-underline cursor-pointer">Go to Dashboard</a>
                </span>
            </div>
        </div>
    </section>
</div>