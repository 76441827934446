export const environment = {
  production: true,
  baseUrl: 'https://api.staging.autobuffy.com/api/v1/user/',
  redirectUrl: 'https://staging.autobuffy.com',
  FACEBOOK_APP_ID: "2401041083283614",
  STRIPE_PUBLIC_KEY: "pk_test_3sba6g7fbnOUFqRT46rbNbmq",
  GPAY_ENVIRONMENT: "TEST",
  GPAY_MERCHANT_ID: "12345678901234567890"
  // GOOGLE_API_KEY:"AIzaSyDkbC4w4PyQhx0ZOhWIg7b1dNZz_az2awE"
};

// GOOGLE_API_KEY:"AIzaSyCuS2irjCC5lPnAryMF6KoePvk5_D_gmJk"
