import { NgModule } from '@angular/core';
import { ClickElsewhereDirective } from './ClickElsewhereDirective';
import { IntersectionObserverDirective } from './intersection-observer.directive';


@NgModule({
    declarations: [ClickElsewhereDirective, IntersectionObserverDirective],
    imports: [],
    exports: [ClickElsewhereDirective, IntersectionObserverDirective]
})
export class DirectivesModule { }
