<header (clickElsewhere)="onOutsideClick($event)">
  <div class="header-bar">
    <div class="px-5 van-container">
      <div class="d-flex header-bar-content">
        <div class="shipping-product">
          <img *ngIf="isBrowser || isMobile" class="shipping-van" src="./assets/images/van.svg" loading="lazy" alt="Loader Car Icon" />
          <span class="ml-2">FREE SHIPPING ON ALL PRODUCTS</span>
        </div>
        <div class="align-items-center review-wrapper">
          <!-- <span (click)="togglePilotModal()" class="truspilot-block py-1 mr-4 pr-4 d-flex align-items-center cursor-pointer">
            <img *ngIf="isBrowser || isMobile" src="./assets/images/green_star.svg" loading="lazy" alt="Green Star Icon" />
            <span class="mx-2 trust-pilot">TrustPilot Rated Excellent</span>
            <img *ngIf="isBrowser || isMobile" src="./assets/images/green_star.svg" loading="lazy" alt="Green Star Icon" />
          </span> -->
          <!-- <div class="trustpilot-widget truspilot-block mr-4 mt-1 d-flex align-items-center cursor-pointer" data-locale="en-US" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="60c7a7f2a685b30001016b35" data-style-height="24px" data-style-width="100%" data-theme="light">
            <a href="https://www.trustpilot.com/review/autobuffy.com" target="_blank" rel="noopener">Trustpilot</a>
          </div> -->
          <span class="review-block py-1 cursor-pointer" (click)="toggleReviewModal()">
            <ng-template *ngTemplateOutlet="ratingModule; context: {$implicit: avgRating}"></ng-template>
          </span>
          <span class="cursor-pointer" (click)="toggleReviewModal()">
            <span> Rating</span> | {{customerReviews.total_count}} +  <span class="website-reviews cursor-pointer">Website Reviews</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- <p-panel id="panelPilot" *ngIf="isShowPilotModal">
    <p-header class="w-100 d-flex justify-content-between align-items-center">
        <span class="avenir-heavy-20">TrustPilot Reviews</span>
        <i class="fi fi-rr-cross-circle cursor-pointer cross-icon" (click)="isShowPilotModal = false"></i>
    </p-header>
    <div class="conatainer" *ngIf="pilotReviews.length > 0;else notFound">
      <div class="mb-3" *ngFor="let review of pilotReviews">
        <div class="d-flex justify-content-between">
          <div class="d-flex pilot-reviews">
            <p-rating [(ngModel)]="review.rating" readonly="true" [cancel]="false"></p-rating>
            <span class="ml-2">{{review.rating}}</span>
          </div>
          <div>
            <p class="avenir-medium-16">{{review.created_at}}</p>
          </div>
        </div>
        <div>
          <p class="avenir-normal-14">{{review.comment}}</p>
        </div>
        <div>
          <span class="avenir-medium-16">{{review.fullname}}</span>
        </div>
        <hr>
      </div>
    </div>
    <div class="text-center" *ngIf="pilotReviewsCount > pilotReviewsLimit">
      <button (click)="getPilotReviews()" class="btn btn-primary mt-3">Load More</button>
    </div>
</p-panel> -->
  <p-panel id="panelReview" *ngIf="isShowReviewModal">
    <p-header class="w-100 d-flex justify-content-between align-items-center">
        <span class="avenir-heavy-20">Website Reviews</span>
        <i class="fi fi-rr-cross-circle cursor-pointer cross-icon" (click)="isShowReviewModal = false"></i>
    </p-header>
    <div class="conatainer" *ngIf="reviews.length > 0;else notFound">
      <div class="mb-3" *ngFor="let review of reviews">
        <div class="d-flex justify-content-between">
          <div class="d-flex user-reviews">
            <p-rating [(ngModel)]="review.rating" readonly="true" [cancel]="false"></p-rating>
            <span class="ml-2">{{review.rating}}</span>
          </div>
          <div>
            <p class="avenir-medium-16">{{review.created_at | date}}</p>
          </div>
        </div>
        <div class="avenir-normal-14">
          {{review.comment}}
        </div>
        <div>
          <span class="avenir-medium-16">{{review.fullname}}</span>
        </div>
        <hr>
      </div>
    </div>
    <div class="text-center" *ngIf="customerReviews.total_count > limit">
      <button (click)="getUserReviews()" class="btn btn-primary mt-3">Load More</button>
    </div>
</p-panel>
<ng-template #notFound>
  <p>No data Found</p>
</ng-template>

  <div class="" [ngClass]="isMobile ? 'px-20': 'px-5'">
    <div class="header-wrapper row pt-4 pb-3 desktop">
      <div class="col-12 col-md-2 pr-0">
        <div class="header-brand-logo">
          <a routerLink="/">
            <img *ngIf="isBrowser || isMobile" width="auto" height="auto" src="./assets/images/home/header/header-auto-buffy-logo.svg" loading="lazy" draggable="false" alt="AutoBuffy" />
          </a>
        </div>
      </div>
      <div class="col-12 col-md-5 pl-0">
        <ng-container *ngIf="versionStatus>0 && !isCheckout">
          <ng-container *ngTemplateOutlet="searchTemplate"></ng-container>
        </ng-container>
      </div>
      <div class="col-12 col-md-5 d-flex align-items-center pl-0">
        <ul class="header-nav-container d-flex align-items-center justify-content-end p-0 m-0 w-100">
          <li href="tel:8884772530" class="call-block nav-blocks d-flex align-items-center pr-3">
            <i class="fi fi-rr-phone-call phone-icon"></i>
            <div class="ml-2 d-flex flex-column">
              <p class="mb-0"> Call Our Experts </p>
              <a class="hover-link" href="tel:8884772530"> 888-477-2530</a>
            </div>
          </li>
          <li class="chat-block nav-blocks d-flex align-items-center pr-3">
            <i class="fi fi-rr-comment"></i>
            <div class="ml-2 d-flex flex-column">
              <p class="mb-0">Live Chat</p>
              <a class="hover-link" (click)="openChat()">Ask Questions</a>
            </div>
          </li>
          <li class="vehicle-block nav-blocks d-flex align-items-center pr-3">

            <i class="fi fi-rr-car-alt position-relative mr-1">
              <div class="badge position-absolute" *ngIf="vehicleData.length>0">{{vehicleData.length}}</div>
            </i>
            
            <div (mouseenter)="toggleVehicleAdd = true" (mouseleave)="toggleVehicleAdd = false" class="ml-2 d-flex flex-column">
              <p class="mb-0">Select vehicle</p>
              <a (click)="desktopToggleVehicleAddOpen()" class="d-flex align-items-center hover-link">
                My Car
                <i class="fi fi-rr-angle-down ml-2 chevron-icon"></i>
              </a>
              <ng-container *ngIf="toggleVehicleAdd">
              <ng-template [ngTemplateOutlet]="addVehicleDropDown"></ng-template>
            </ng-container>
            </div>
          </li>
          <li class="account-block nav-blocks d-flex align-items-center position-relative pr-3">
            <i class="fi fi-rr-user phone-icon mb-1"></i>
            <div class="ml-2 d-flex flex-column">
              <p class="mb-0"> Hello, {{is_Logged? userData.first_name:'Guest'}} </p>
              <a class="d-flex align-items-center hover-link" (click)="toggleAccount($event)"
              (clickElsewhere)="showAccountDrop=false" *ngIf="is_Logged;else not_logged_content"
              (mouseover)="showAccountDrop = true" (mouseleave)="showAccountDrop = false">
                My Account
                <i class="fi fi-rr-angle-down ml-2 chevron-icon"></i>
                <div class="home-account-menu animate-drop-class position-absolute" *ngIf="showAccountDrop">
                  <ul>
                    <li class="menu" (click)="toggleAccount($event);navigateToDashboard()">Dashboard </li>
                    <li class="menu" (click)="toggleAccount($event);logout()">Logout</li>
                  </ul>
                </div>
              </a>
              <ng-template #not_logged_content><a class="d-flex align-items-center hover-link" (click)="login()">Sign In<div
                class="arrow-down"></div></a></ng-template>
            </div>
            
          </li>
          <li class="cart-block nav-blocks d-flex align-items-center nav-drop position-relative">
            <a routerLink="/cart" class="position-relative">
              <i class="fi fi-rr-shopping-cart cart-icon"></i>
              <div class="item-count position-absolute d-flex align-items-center justify-content-center text-white">{{itemCount}}</div>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="header-wrapper pt-4 pb-1 mobile justify-content-between">
      <ul class="header-nav-container p-0 m-0 d-flex">
        <li class="account-block d-flex align-items-center position-relative mr-2 mr-sm-4">
          <i (click)="toggleMobileMenu()" loading="lazy" draggable="false" class="fi fi-rr-bars-staggered"></i>
        </li>
        <li class="call-block d-flex align-items-center mr-2 mr-sm-4">
          <a href="tel:8884772530">
            <i class="fi fi-rr-phone-call phone-icon"></i>
          </a>
        </li>
      </ul>
      <div class="header-brand-logo">
        <a routerLink="/">
          <img *ngIf="isBrowser || isMobile" width="auto" height="auto" src="./assets/images/home/header/header-auto-buffy-logo.svg" loading="lazy" draggable="false" alt="AutoBuffy" />
        </a>
      </div>
      <ul class="header-nav-container p-0 m-0 d-flex">
        <li class="account-block d-flex align-items-center position-relative mr-2 mr-sm-4"
         (click)="toggleAccount($event)" (clickElsewhere)="showAccountDrop=false">
          <i class="fi fi-rr-user phone-icon mb-1"></i>
        </li>
        <div class="home-account-menu animate-drop-class position-absolute" *ngIf="showAccountDrop">
          <ul>
            <li class="menu" (click)="toggleAccount($event);navigateToDashboard()">Dashboard </li>
            <li class="menu" (click)="toggleAccount($event);logout()">Logout</li>
          </ul>
        </div>
        <li class="cart-block d-flex align-items-center nav-drop position-relative">
          <a routerLink="/cart" class="position-relative">
            <i class="fi fi-rr-shopping-cart phone-icon cart-icon"></i>
            <div class="item-count position-absolute d-flex align-items-center justify-content-center text-white">{{itemCount}}</div>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="base-container mx-auto mobile px-3 py-0">
    <ng-container>
      <ng-container *ngTemplateOutlet="searchTemplate"></ng-container>
    </ng-container>
  </div>

  <div class="home-header-add-vehicle animate-drop-class-extra position-fixed"
  [ngClass]="{'is-landing-page-wrapper':isLandingPage ,'show-it-below':!isLandingPage}" *ngIf="showAddVehicle">
  <div class=" home-header-add-vehicle-wrapper d-flex flex-column align-items-center justify-content-center">
    <button type="button" class="header-home-popup-close" (click)="resetValuesSearch()" *ngIf="!isLandingPage">
      <ngx-picture *ngIf="isBrowser || isMobile" src="./assets/images/home/header/header-popup-close-icon.svg" alt="autoBuffy popup close"
        [lazyLoad]="true" type="image/svg"></ngx-picture>
    </button>
    <div class="home-card-dropdown-entries d-flex align-items-center  justify-content-between ">
      <ng-container *ngIf="isLandingPage; else normalAddvehicle">
        <div class="home-add-vehicle-header d-flex justify-content-between align-items-center ">
          <h2>Select your vehicle</h2>
            <i class="fi fi-rr-cross cursor-pointer" (click)="resetValuesSearch()"></i>
        </div>
      </ng-container>
      <ng-template #normalAddvehicle>
        <div class="home-add-vehicle-header d-flex align-items-center">
          <ngx-picture *ngIf="isBrowser || isMobile" src="./assets/images/home/header/header-add-vehicle-icon.svg" alt="vehicle icon"
            class="popup-select-vehicle" [lazyLoad]="true" type="image/svg"></ngx-picture>
          <h2>Select your vehicle</h2>
        </div>
      </ng-template>
      <div class="d-flex align-items-center d-grid home-dropdown-wrapper">
        <p-dropdown id="year-add-vehicle-id" styleClass="home-search-drop-header"
          panelStyleClass="home-search-panel-style" scrollHeight="370px" placeholder="Year"
          (keyup.enter)="enterKeySelection('year-add-vehicle-id','home-search-drop-header')"
          [dropdownIcon]="headerpage.yearLoading ? 'pi pi-spin pi-spinner' : 'fi fi-rr-caret-down'"
          [disabled]="yearArray.length==0 || headerpage.yearDisabled" [options]="yearArray" [(ngModel)]="year"
          (onHide)="onHide(1,year)" #yearViewHeaderId (onChange)="clearPDropdown(yearViewHeaderId, 1, year )" filter="true"
          optionLabel="year" optionValue="id">
        </p-dropdown>
        <p-dropdown id="make-add-vehicle-id" styleClass="home-search-drop-header"
          panelStyleClass="home-search-panel-style" scrollHeight="370px" placeholder='Make'
          (keyup.enter)="enterKeySelection('make-add-vehicle-id','home-search-drop-header')"
          [dropdownIcon]="headerpage.makeLoading ? 'pi pi-spin pi-spinner' : 'fi fi-rr-caret-down'"
          [disabled]="makeArray.length==0 || headerpage.makeDisabled" [options]="makeArray" [(ngModel)]="make"
          (onHide)="onHide(2,make)" #makeViewHeaderId (onChange)="clearPDropdown(makeViewHeaderId, 2, make)" filter="true"
          optionLabel="name" optionValue="id">
        </p-dropdown>
        <p-dropdown id="model-add-vehicle-id" styleClass="home-search-drop-header"
          (keyup.enter)="enterKeySelection('model-add-vehicle-id','home-search-drop-header')"
          panelStyleClass="home-search-panel-style" scrollHeight="370px" placeholder="Model" #modelViewHeaderId
          [dropdownIcon]="headerpage.modelLoading ? 'pi pi-spin pi-spinner' : 'fi fi-rr-caret-down'"
          (onChange)="clearPDropdown(modelViewHeaderId,3, model)" [disabled]=" modelArray.length==0||headerpage.modelDisabled"
          [options]="modelArray" [(ngModel)]="model" (onHide)="onHide(3,model)" filter="true" optionLabel="name"
          optionValue="id">
        </p-dropdown>
        <ng-container *ngIf="isLandingPage; else normalAddvehicleButton">
          <button type="button" class="button-search d-flex align-items-center justify-content-center" aria-label="search" [disabled]="disableSearch()"
            (click)="addVehicleToLocal()">
              <i class="fi fi-br-search text-white mr-2 mt-1"></i>
             <span>Search</span>
          </button>
        </ng-container>
        <ng-template #normalAddvehicleButton>
          <button type="button" aria-label="search" [disabled]="disableSearch()"
            (click)="addVehicleToLocal()">GO</button>
        </ng-template>
      </div>
    </div>
    <p *ngIf="selectErr" class="header-search-error">{{selectErr}}</p>
  </div>
</div>

  <!-- <div *ngIf="!sharedService.showSearchBar" class="vehicle-wrapper desktop px-3">
    <div class="base-container mx-auto">
      <div class="d-flex py-2">
        <div class="vehicle-select d-flex align-items-center mr-3">
          <img class="w-100" src="./assets/images/vehicle-logo.png" loading="lazy" draggable="false" alt="Vehicle Logo" />
          <p class="mb-0 ml-3">Select Vehicle</p>
        </div>
        <div class="select-wrapper mx-1">
          <select class="px-3 py-2">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div class="select-wrapper mx-1">
          <select class="px-3 py-2">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div class="select-wrapper mx-1">
          <select class="px-3 py-2">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div class="search-wrapper position-relative flex-grow-1 ml-2">
          <input type="text" name="search autobuffy" class="placeholder-class"
            placeholder="Search By Part or Kit Number, Brand, or Part Type">
          <button type="submit" name="search" class="position-absolute p-0">
            <i class="fi fi-rr-search"></i>
          </button>
        </div>
      </div>
    </div>
  </div> -->
</header>
<ng-template #addVehicleDropDown>
  <div class="add-vehicle-drop-container desk dropdown-container card">
    <div class="add-vehicle-drop-container-wrappper  d-flex flex-column">
      <ng-container *ngIf="vehicleData && vehicleData.length>0">
        <div class="add-vehicle-header-details  d-flex align-items-center justify-content-between w-100">
          <h2>Add Vehicle</h2>
          <button type="button" (click)="clearAll()">Clear All</button>
        </div>
        <div class="add-vehicle-header-details-contents d-flex flex-column align-items-center">
          <ng-container *ngFor="let items of vehicleData;let i = index">
            <div class="vehicle-data d-flex align-items-center justify-content-between">
              <p (click)="changeMyVehicle(items)" class="vehicle-name"
                [ngClass]="{'active':items.id ==vehicleSelectedId}">
                {{items.year + ' '+ items.make + '
                '+items.model }}</p>
              <div class="active-main" (click)="changeMyVehicle(items)" *ngIf="items.id ==vehicleSelectedId">
                Selected</div>
              <div class="w-85" *ngIf="items.id !==vehicleSelectedId"></div>
              <div (click)="changeMyVehicle(items)" class="right-arrow"></div>
              <button type="button" class="delete " (click)="deleteEntires(items)">
                  <i class="fi fi-rr-trash delete-icon"></i>
              </button>
            </div>
          </ng-container>

        </div>
      </ng-container>
      <button type="button" class="add-button" (click)="openAddVehicle(1)"> Add Vehicle</button>
    </div>
  </div>
</ng-template>
<ng-template #searchTemplate>
  <div class="home-header-search-container animate-drop-class position-relative ml-0">
    <form [formGroup]="searchForm" novalidate autocomplete="off">
      <div class="search-bar-container d-flex justify-content-center">
        <input type="text" name="search autobuffy" class="placeholder-class search-bar" formControlName="searchBar"
          aria-label="searchautobuffy" (focus)="checkOnSearchFocus()"
          [placeholder]="!isMobile ? 'Search By Part or Kit Number, Brand, or Part Type': 'Find Part Number, Brand, or Part Type'">
        <button *ngIf="!showLoadingSearch" type="submit" name="search" class="search search-icon-btn position-absolute" [disabled]="searchForm.invalid"
          (click)="submitSearch()">
          <!-- <ngx-picture src="./assets/images/home/header/header-search-icon.svg" alt="search autoBuffy" [lazyLoad]="true"
            class="autobuffy-search" type="image/svg"></ngx-picture> -->
            <i class="fi fi-br-search search-icon"></i>
        </button>
        <div class="position-absolute search-loader" *ngIf="showLoadingSearch">
          <app-loader [width]="20" [height]="20"></app-loader>
        </div>
      </div>
      <!-- <div class="position-absolute search-loader" *ngIf="showLoadingSearch">
        <app-loader [width]="20" [height]="20"></app-loader>
      </div> -->

      <div class="home-search-dropdown position-absolute" *ngIf="!searchForm.invalid">
        <div class="home-search-wrapper">
          <ng-container *ngIf="searchResult;else no_search_found">
            <ul *ngIf="searchResult && searchResult.parts && searchResult.parts.length>0">
              <li class="home-search-header">Parts</li>
              <li *ngFor="let items of searchResult.parts" class="w-100">
                <a [routerLink]="['/details']" [queryParams]="{id: items.id}" (click)="triggerBoth()">
                  <p class="w-100 m-0"> {{items.part_number}}</p>
                </a>
              </li>
              <li class="loadmore-label pt-3 pb-4" *ngIf="searchedParts.length > loadMoreVal && searchResult.parts.length != searchedParts.length" (click)="loadSearchData(searchedParts, 'parts')">load more</li>
            </ul>
            <ul *ngIf="searchResult && searchResult.kits && searchResult.kits.length>0">
              <li class="home-search-header">Kits</li>
              <li *ngFor="let items of searchResult.kits" class="w-100">
                <a [routerLink]="['/details']" [queryParams]="{id: items.id ,is_part:false,q:false}"
                  (click)="triggerBoth()">
                  <p class="w-100 m-0"> {{items.item_number}}</p>
                </a>
              </li>
              <li class="loadmore-label pt-3 pb-4" *ngIf="searchedKits.length > loadMoreVal && searchResult.kits.length != searchedKits.length" (click)="loadSearchData(searchedKits, 'kits')">load more</li>
            </ul>
            <ul *ngIf="searchResult && searchResult.brands && searchResult.brands.length>0">
              <li class="home-search-header">Brands</li>
              <li *ngFor="let items of searchResult.brands" class="w-100">
                <a [routerLink]="items.redirect_url" (click)="triggerBoth()">
                  <p class="w-100 m-0"> {{items.name}}</p>
                </a>
              </li>
              <li class="loadmore-label pt-3 pb-4" *ngIf="searchedBrand.length > loadMoreVal && searchResult.brands.length != searchedBrand.length" (click)="loadSearchData(searchedBrand, 'brands')">load more</li>
            </ul>
            <ul *ngIf="searchResult && searchResult.part_types && searchResult.part_types.length>0">
              <li class="home-search-header">Part Types</li>
              <li *ngFor="let items of searchResult.part_types" class="w-100">
                <a [routerLink]="items.redirect_url" (click)="triggerBoth()">
                  <p class="w-100 m-0">{{items.name}}</p>
                </a>
              </li>
              <li class="loadmore-label pt-3 pb-4" *ngIf="searchedPartTypes.length > loadMoreVal && searchResult.part_types.length != searchedPartTypes.length" (click)="loadSearchData(searchedPartTypes, 'partTypes')">load more</li>
            </ul>
          </ng-container>
          <ng-template #no_search_found>
            <p class="no-results d-flex align-items-center">
              No results found
            </p>
          </ng-template>

        </div>

      </div>
    </form>

  </div>
</ng-template>



<app-login-signup #userAccess="userAccess"></app-login-signup>
<ng-template #ratingModule let-rating>
  <div class="rating d-flex align-items-center justify-content-lg-start">
      <p-rating [ngModel]="rating" readonly="true" [cancel]="false"></p-rating>
      <span class="ml-2 mr-lg-2">{{ rating }}</span>
  </div>
</ng-template>

<p-sidebar [(visible)]="toggleTab" *ngIf="toggleTab" styleClass="home-menu-tab-sidebar" blockScroll="true" position="right">
  <div class="home-menu-tab-sidebar-wrapper position-relative" *ngIf="toggleTab">
    <div class="d-flex align-items-center home-header-search-nav-tab-header justify-content-between">
      <div class="d-flex align-items-center home-header-title">
        <ngx-picture *ngIf="isBrowser || isMobile" src="./assets/images/home/header/header-account-icon.svg" alt="AutoBuffy Account" [lazyLoad]="true"
          type="image/svg"></ngx-picture>
        <h3>Hello, {{is_Logged? userData.first_name:'Guest'}}</h3>
      </div>
      <button type="button" name="tab menu close" (click)="toggleTabMenu()">
        <ngx-picture *ngIf="isBrowser || isMobile" src="./assets/images/home/header/header-menu-close-icon.svg" alt="autoBuffy tab menu close"
          [lazyLoad]="true" type="image/svg"></ngx-picture>
      </button>
    </div>
    <div class="home-header-search-nav-tab" [style.height.px]="calculateHeight()">
      <ul>
        <li class="">
          <p>Call Our Experts</p>
          <a href="tel:8884772530">888-477-2530</a>
        </li>
        <li class="">
          <p>Live Chat</p>
          <a (click)="toggleTabMenu();openChat()">Ask Questions</a>
        </li>
        <li class="">
          <ng-container *ngIf="is_Logged;else not_logged_content_tab">
            <p>My Account</p>
            <a (click)="toggleTabMenu();navigateToDashboard()">Dashboard</a>
            <br>
            <a (click)="toggleTabMenu();logout()">Logout</a>
          </ng-container>
          <ng-template #not_logged_content_tab>
            <a (click)="toggleTabMenu();login()">Sign In</a>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
</p-sidebar>

<p-sidebar [(visible)]="toggleMobile" *ngIf="toggleMobile" styleClass="home-menu-mob-sidebar" blockScroll="true" position="left">
  <div class="home-menu-tab-sidebar-wrapper position-relative p-0" *ngIf="toggleMobile">
    <div class="d-flex align-items-center home-header-search-nav-tab-header justify-content-between">
      <div class="d-flex align-items-baseline home-header-title">
        <ngx-picture *ngIf="isBrowser || isMobile" src="./assets/images/home/header/header-account-icon.svg" alt="AutoBuffy Account" [lazyLoad]="true"
          type="image/svg"></ngx-picture>
        <h3>Hello, {{is_Logged? userData.first_name:'Guest'}}</h3>
      </div>
      <button type="button" name="tab menu close" (click)="toggleMobileMenu()">
        <img *ngIf=" isBrowser || isMobile" width="auto" height="auto" src="./assets/images/home/header/header-menu-close-icon.svg" loading="lazy"
          draggable="false" alt="autoBuffy mobile menu close " />
      </button>
    </div>
    <div class="home-header-search-nav-tab" [style.height.px]="calculateHeight()">
      <ul>
        <li class="">
          <ng-container *ngIf="is_Logged;else not_logged_content_tab">
            <p>My Account</p>
            <a (click)="toggleMobileMenu();navigateToDashboard()">Dashboard</a>
            <br>
            <a (click)="toggleMobileMenu();logout()">Logout</a>
          </ng-container>
          <ng-template #not_logged_content_tab>
            <a (click)="toggleMobileMenu();login()">Sign In</a>
          </ng-template>
        </li>
        <li>
          <div class="d-flex align-items-center cursor-pointer w-100" (click)="toggleAddVehicle()">
            <div class="position-relative">
              <ngx-picture *ngIf="isBrowser || isMobile" src="./assets/images/home/header/header-vehicle-icon.svg" alt="AutoBuffy  vehicle icon"
                class="reverse" [lazyLoad]="true" type="image/svg"></ngx-picture>
              <div class="badge position-absolute" *ngIf="vehicleData.length>0">{{vehicleData.length}}</div>
            </div>
            <div class="p-l-15">
              <p class="w-100 cursor-pointer d-flex align-items-center justify-content-between"><span>Add Vehicle</span>
                <span class="toggle-arrow"></span>
              </p>
              <div class="content" class="active-color">{{selectedVehicleName}}</div>
            </div>
          </div>
        </li>
        <li class="">
          <p>Customer Service</p>
          <div class="content">Hours May Vary</div>
          <div class="content">M-F: 8:30 AM - 5 PM EST</div>
          <div class="content">Saturday: 8:30 AM - 2 PM EST</div>
          <div class="content">Sunday: Closed</div>
        </li>
        <li class="">
          <p>Shopping Assistance</p>
          <div class="content">Available Everyday, 24/7</div>
          <div class="content">Holiday Hours May Vary</div>
        </li>
      </ul>
    </div>
  </div>
</p-sidebar>

<p-sidebar [showCloseIcon]="true" [(visible)]="showAddVehicleMob" *ngIf="showAddVehicleMob" styleClass="home-menu-mob-sidebar" blockScroll="true" position="left">
  <div class="home-menu-tab-sidebar-wrapper position-relative" *ngIf="showAddVehicleMob">
    <div class="d-flex align-items-center home-header-search-nav-tab-header justify-content-between">
      <div class="d-flex align-items-baseline home-header-title">
        <ngx-picture *ngIf="isBrowser ||isMobile" src="./assets/images/home/back-arrow.svg" alt="AutoBuffy  back" class="cursor-pointer"
          [lazyLoad]="true" type="image/svg" (click)="toggleAddVehicle()"></ngx-picture>
        <h3>Add Vehicle</h3>
      </div>
      <button type="button" name="tab menu close" (click)="showAddVehicleMob=false">
        <ngx-picture *ngIf="isBrowser || isMobile" src="./assets/images/home/header/header-menu-close-icon.svg" alt="autoBuffy mobile menu close"
          [lazyLoad]="true" type="image/svg"></ngx-picture>
      </button>
    </div>
    <div class="home-header-search-nav-tab" [style.height.px]="calculateHeight()">
      <div class="home-header-add-vehicle-mob">
        <ng-container *ngFor="let items of vehicleData;let i = index">
          <div class="vehicle-data  cursor-pointer d-flex align-items-center justify-content-between">
            <p (click)="showAddVehicleMob=false;changeMyVehicle(items)" class="w-100"
              [ngClass]="{'active':items.id ==vehicleSelectedId}">
              {{items.year + ' '+ items.make + '
              '+items.model }}</p>
            <div class="active-main" (click)="showAddVehicleMob=false;changeMyVehicle(items)"
              *ngIf="items.id ==vehicleSelectedId">Selected</div>
            <div class="w-85" *ngIf="items.id !==vehicleSelectedId"></div>
            <div (click)="showAddVehicleMob=false;changeMyVehicle(items)" class="right-arrow"></div>
            <button type="button" class="delete " (click)="showAddVehicleMob=false;deleteEntires(items)">
                <i class="fi fi-rr-trash delete-icon"></i>
            </button>
          </div>
        </ng-container>
        <div class="add-mobile-actions d-flex flex-column align-items-center justify-content-center">
          <button type="button" class="add-button w-100" (click)="showAddVehicleMob=false;openAddVehicle(1)"> Add
            Vehicle</button>
          <button type="button" class="clear" *ngIf="vehicleData.length>0"
            (click)="showAddVehicleMob=false;clearAll()">Clear All</button>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>

<div class="common-floating-toolbar" *ngIf="passed && isHome">
  <div id="common-floating-toolbar-id"
    class="base-container container common-floating-toolbar-wrapper d-flex align-items-center w-100 position-relative">
    <a [routerLink]="['/']" class="logo-image" >
      <ngx-picture *ngIf="isBrowser || isMobile" src="./assets/images/home/auto-buffy-logo-floating.webp" alt="Autobuffy" [lazyLoad]="true"
        type="image/webp" id="floating-logo-id"></ngx-picture>
    </a>
    <div class="floating-search-home" id="floating-search-id">
      <ng-container *ngTemplateOutlet="searchTemplate"></ng-container>
    </div>
  </div>
</div>