import { Component, HostListener, OnInit } from '@angular/core';
import { DataService } from '../shared/common-services/data.service';

@Component({
  selector: 'app-footer-new-design',
  templateUrl: './footer-new-design.component.html',
  styleUrls: ['./footer-new-design.component.scss']
})
export class FooterNewDesignComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkIsMobile();
  }
  
  socialIcons: any = [];
  contactAndHelpLinks: any = [];
  chatAndTalkLinks: any = [];
  phoneAndMailLinks: any = [];
  termsAndPolicyLinks: any = [];
  isMobile: boolean = false;

  constructor(private sharedService: DataService) { }

  ngOnInit() {
    this.checkIsMobile();
    this.getSocialIcons();
    this.getContactAndHelpLinks();
    this.getChatAndTalkLinks();
    this.getTermsAndPolicyLinks();
    this.getPhoneAndMailLinks();
  }

  openChat() {
    this.sharedService.emitChatPopupTrigger();
  }
  

  goToOrderStatus(): void {
    this.sharedService.openOrderStatusForm();
  }

  getSocialIcons() {
    this.socialIcons = [
      { name: 'facebook', link: 'https://www.facebook.com/autobuffy', isActive: false, active: '/assets/images/facebook-active-icon.svg', inActive: '/assets/images/fb.svg' },
      { name: 'instagram', link: 'https://www.instagram.com/autobuffy/', isActive: false, active: '/assets/images/insta-active-icon.svg', inActive: '/assets/images/insta.svg' },
      { name: 'twitter', link: 'https://twitter.com/autobuffy/', isActive: false, active: '/assets/images/twitter-active-icon.svg', inActive: '/assets/images/tweeter.svg' },
    ];
  }

  getContactAndHelpLinks() {
    this.contactAndHelpLinks = [
      { name: 'About Us', classes: 'router-links', link: '/about' },
      { name: 'Order Tracking', classes: 'router-links chat-action', link: '' },
      // {name: 'Reviews',classes: 'router-links chat-action', link: ''},
      { name: 'Contact', classes: 'router-links', link: '/contact' },
      // {name: 'Return & Cancellation',classes: 'router-links', link: ''},
      { name: 'Way to Pay', classes: 'router-links', link: '/privacy' },
      { name: 'Help Center', classes: 'router-links', link: '/help-center' },
      { name: 'FAQ', classes: 'router-links', link: '/help-center', },
      { name: 'Delivery Options', classes: 'router-links', link: '/privacy', },
    ];
  }

  getChatAndTalkLinks() {
    this.chatAndTalkLinks = [
      { name: 'Live Chat', text: 'For advice before you buy, video call with one of our experts', class: 'non-active-link', href:"#"},
      { name: 'Auto Talks', text: 'Get the latest information and inspiration from our blog', class: '', href:"https://www.blog.autobuffy.com" },
    ];
  }

  getTermsAndPolicyLinks() {
    this.termsAndPolicyLinks = [
      { name: 'Privacy Policy', link: '/privacy', liClass: 'mr-3' },
      { name: 'Terms of Agreement', link: '/terms', liClass: 'mr-3' },
      { name: 'Sitemap', link: '', liClass: 'd-none' },
    ];
  }

  getPhoneAndMailLinks() {
    this.phoneAndMailLinks = [
      { href: 'tel:8884772530', name: 'Phone', liClass: 'd-flex align-items-center', aClasses: 'router-links common-styles-links', icon: 'fi fi-rr-phone-call', text: '888-477-2530' },
      { href: '/help-center', name: 'Mail', liClass: 'd-flex align-items-center', aClasses: 'router-links common-styles-links', icon: 'fi fi-rr-envelope', text: 'help@autobuffy.com' },
      { href: '', name: 'Shopping Assistance', liClass: 'd-flex align-items-center notShownInMobile', aClasses: 'd-flex align-items-center common-styles-links non-active-link', icon: '', text: 'Available Everyday, 24/7' },
      { href: '', name: 'Follow Us', liClass: 'd-flex align-items-center', aClasses: 'd-flex align-items-center common-styles-links non-active-link', icon: '', text: '#ExpertlyCurted' },
    ];
  }

  checkIsMobile(){
    this.isMobile = window.innerWidth < 768 ? true : false;
  }
}
