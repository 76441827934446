import { NgModule, Injectable } from "@angular/core";
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { LoginSignupService } from "./login-signup/core/login-signup.service";
import { DataService } from "./shared/common-services/data.service";
import { SeoService } from "./shared/common-services/seo.service";
import { ThankYouPageComponent } from "./thank-you-page/thank-you-page.component";



@Injectable()
export class CanViewDashboard  {
  constructor(private authService: LoginSignupService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.getAuthInfo()) {
      return true;
    } else {
      this.router.navigate(["/"], { queryParams: { order: true } });
      return false;
    }
  }
}
@Injectable()
export class CanLoadRoute  {
  constructor( public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.params.make == "product") this.router.navigate(["error"]);
    return true;
  }
}

@Injectable()
export class CanResetFilter  {
  constructor(private sharedService: DataService) {
    this.sharedService.resetFilter();
  }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}

const productDetailsModule = () => import("./product-details/core/product-details.module").then(m => m.ProductDetailsModule);
const brandContentModule = () => import('./brand-content/core/brand-content.module').then(m => m.BrandContentModule);
const allProductsModule = () => import("./all-products/core/all-products.module").then(m => m.AllProductsModule);


const routes: Routes = [
  { path: "", loadChildren: () => import("./home-page/core/home-page.module").then(m => m.HomePageModule) },
  { path: "details", loadChildren: productDetailsModule },
  { path: "cart", loadChildren: () => import("./cart/core/cart.module").then(m => m.CartModule) },
  { path: "checkout", loadChildren: () => import("./checkout/core/checkout.module").then(m => m.CheckoutModule) },
  { path: "dashboard", loadChildren: () => import("./user-dashboard/core/user-dashboard.module").then(m => m.UserDashboardModule), canActivate: [CanViewDashboard] },
  { path: "track-order", loadChildren: () => import("./track-component/core/track.module").then(m => m.TrackModule) },
  { path: "review", loadChildren: () => import("./review/core/review.module").then(m => m.ReviewModule) },
  { path: "about", loadChildren: () => import("./about/core/about.module").then(m => m.AboutModule) },
  { path: "contact", loadChildren: () => import("./contact/core/contact.module").then(m => m.ContactModule) },
  { path: "help-center", loadChildren: () => import("./faq/core/faq.module").then(m => m.FaqModule) },
  { path: "terms", loadChildren: () => import("./terms/core/terms.module").then(m => m.TermsModule) },
  { path: "privacy", loadChildren: () => import("./privacy/core/privacy.module").then(m => m.PrivacyModule) },
  { path: "unsubscribe-order-review", loadChildren: () => import("./unsubscribeorder/core/unsubscribeorder.module").then(m => m.UnsubscribeorderModule) },
  { path: "error", loadChildren: () => import("./error-page/core/error-page.module").then(m => m.ErrorPageModule) },

  // All Products Module
  { path: "all-products", loadChildren: allProductsModule},
  { path: "all-products/:year/:make/:model", loadChildren: allProductsModule},

  // Product Details Module
  { path: "product/:itemNumber/:productTitle", loadChildren: productDetailsModule, canActivate: [CanResetFilter] },
  { path: "product/:year/:make/:model/:engine/:partType/:itemNumber/:title/:unicode", loadChildren: productDetailsModule },
  { path: "product/:year/:make/:model/:partType/:itemNumber/:title/:unicode", loadChildren: productDetailsModule },
  { path: "product/:year/:make/:model/:itemNumber/:title/:unicode", loadChildren: productDetailsModule },
  { path: "product/:year/:make/:model/:itemNumber/:title", loadChildren: productDetailsModule },
  { path: "kit/:year/:make/:model/:engine/:partType/:kitNumber/:title/:unicode", loadChildren: productDetailsModule },
  { path: "kit/:year/:make/:model/:partType/:kitNumber/:title/:unicode", loadChildren: productDetailsModule },
  { path: "kit/:year/:make/:model/:kitNumber/:title/:unicode", loadChildren: productDetailsModule },
  { path: "kit/:year/:make/:model/:kitNumber/:title", loadChildren: productDetailsModule },
  { path: "kit/:kitNumber/:kitTitle", loadChildren: productDetailsModule, canActivate: [CanResetFilter] },

  // Brand Content Module
  { path: "category/:category", loadChildren: brandContentModule },
  { path: "part-type/:partType", loadChildren: brandContentModule },
  { path: "brand/:id", loadChildren: brandContentModule},
  { path: "brand/:id/:brandname", loadChildren: brandContentModule },
  { path: "search", loadChildren: brandContentModule },
  { path: ":make/:partType", loadChildren: brandContentModule, canActivate: [CanLoadRoute] },
  { path: ":make/:model/:partType", loadChildren: brandContentModule, canActivate: [CanLoadRoute] },

  // thank you page
  { path: "thank-you", component: ThankYouPageComponent,data: {
    title: 'AutoBuffy - Thank you',
    description: 'Thank you for choosing AutoBuffy',
    image: 'https://www.autobuffy.com/assets/images/home/home-banner-img.webp'
  }},
  // If no route matches, show error page.
  { path: "product", redirectTo: "/" },
  { path: "kit", redirectTo: "/" },
  { path: "**", redirectTo: "/error" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: "enabledBlocking", scrollPositionRestoration: "top", onSameUrlNavigation: "reload", anchorScrolling: 'enabled' })],
  exports: [RouterModule],
  providers: [CanViewDashboard, CanLoadRoute, CanResetFilter]
})
export class AppRoutingModule {
  constructor(private seoService: SeoService) {
    this.seoService.initiateRouter();
  }
}
