<p-dialog [(visible)]="popup" blockScroll="true" modal="true" baseZIndex="999991" [closeOnEscape]="alert.escclose" [draggable]="false"
  responsive="true">
  <div class="alert-container">
    <ng-container *ngIf="alert.type">
      <img width="auto" height="auto" src="./assets/images/alert-success-icon.webp"  loading="lazy" draggable="false" [hidden]="alert.type === 'error'" />
      <img width="auto" height="auto" src="./assets/images/alert-error-icon.webp"   loading="lazy" draggable="false" [hidden]="alert.type === 'success'" />
    </ng-container>
    <div class="alert-body">
      <label class="message" [innerHTML]="alert.message" ></label>
      <div class="actions">
        <button type="button" class="button ok-btn" (click)="action('ok')">OK</button>
      </div>
    </div>
  </div>
</p-dialog>