<div class="breadcrumbs">
    <div class="container-fluid breadcrumbs-container" *ngIf="showBreadCrumbs && breadCrumbsArray.length>0">
        <ul class="m-0 d-flex align-items-center list-unstyled">
            <ng-container *ngFor="let items of breadCrumbsArray;let l = last">
                <li>
                    <a [ngClass]="{'pl-2': l== 0}" class="breadcrumb-active text-uppercase" [routerLink]="items.link" (click)="onRouterChange(items)"
                        *ngIf="items.isLink && !items.click">{{items.name}}</a>
                    <a class="breadcrumb-active text-uppercase" *ngIf="items.isLink && items.click"
                        (click)="navigateEvents(items)">{{items.name}}</a>
                    <a class="default-href text-uppercase" *ngIf="!items.isLink && !items.click">{{items.name}}</a>
                </li>
                <div class="px-2" *ngIf="!l">/</div>
            </ng-container>
        </ul>
    </div>
</div>