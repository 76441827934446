import { Component, OnInit, ViewChild, ElementRef, OnDestroy, HostListener, Inject, ViewChildren, QueryList, PLATFORM_ID } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { CartService } from "src/app/cart/core/cart.service";
import { CheckoutDesktopService } from "src/app/checkout/core/checkout-desktop.service";
import { of, Subscription } from "rxjs";
import { debounceTime, filter, switchMap } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { DataService } from "../shared/common-services/data.service";
import { LoginSignupService } from "../login-signup/core/login-signup.service";
import { PILOT_REVIEWS } from "../app.constants";

declare let Swiper: any;

@Component({
  selector: 'app-header-new-design',
  templateUrl: './header-new-design.component.html',
  styleUrls: [
              "./old-header-css/header.component.scss", 
              "./old-header-css/desktop.scss", 
              "./old-header-css/tablet.scss", 
              './header-new-design.component.scss',
              "./css/mobile.scss", 
              "./css/tablet.scss", 
              "./css/desktop.scss", 
              "./css/header.scss",
              ],
  host: {
    "(window:click)": "closeDropdowns($event)"
  }
})
export class HeaderNewDesignComponent implements OnInit, OnDestroy{
  offset: number = 0;
  pilotReviewsCount: number = 0;
  showSearchBar = true;
  searchtext: string = '';
  showHeader = true;
  @ViewChild("userAccess", { static: false }) userAccess: any;
  searchForm: FormGroup;
  versionStatus: any;
  toggleMobile = false;
  toggleVehicleAdd = false;
  toggleTab = false;
  toggleExtraMenus = [false, false];
  userData : any = {};
  is_Logged = false;
  itemCount = 0;
  itemsInCart = [];
  cartUpdateSubscription: Subscription;
  cartDetailTriggerSubscription: Subscription;
  cartMergeSubscription: Subscription;
  cartResetSubscription: Subscription;
  placeOrderMobileTriggerSubscription: Subscription;
  placeOrderTriggerSubscription: Subscription;
  userLoggedInTriggerSubscription: Subscription;
  userLoggedOutTriggerSubscription: Subscription;
  performLoginTriggerSubscription: Subscription;
  showAccountDrop = false;
  showOrderStatusTriggerSubsription: Subscription;
  searchResult: any = '';
  showLoadingSearch = false;
  showSearchDrop = false;
  isCheckout = true;
  isHome = true;
  isShowReviewModal: boolean = false;
  isShowPilotModal: boolean = false;
  reviews: any[] = [];
  limit: number = 20;
  pilotReviewsLimit: number = 10;
  pilotReviews: any[] = [];
  @ViewChildren('panelRef') panelsRef: QueryList<any>;
  panels = [
    {id:'panelReview'},
    {id:'panelPilot'}
  ]; 

  // Add Vehicle
  showAddVehicle = false;
  year = {};
  make = {};
  model = {};
  yearArray = [];
  makeArray = [];
  modelArray = [];
  getParams = { year: '', make: '', model: '' };
  yearMakeModelDataSubscription: Subscription;
  vehicleData = [];
  vehicleSelectedId = '';
  selectedVehicleName = '';
  showAddVehicleMob = false;
  showAddVehicleDataSubscription: Subscription;
  vehicleSelectionIndex = 1;
  headerpage = {
    yearLoading: false,
    makeLoading: false,
    modelLoading: false,
    yearDisabled: false,
    makeDisabled: false,
    modelDisabled: false,
  };
  selectErr = '';

  // PartType Search
  showPartTypeSearch = false;
  emitPartTypeDataSubscription: Subscription;
  searchPartTypes = [];
  partypeSearch = '';
  localsearchPartTypes = [];
  partSearchData: any;
  showSlider: boolean = true;

  // Floating toolbar
  passed: boolean = false;
  isMobile: boolean = false;
  customerReviews: any = {};
  avgRating: number = 0;
  yearObj: any = {};
  makeObj: any = {};
  modelObj: any = {};
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkDeviceMode();
    this.showHideToolbar();
  }

  // PreselectMakeYearModel

  preSelectMakeYearModelSubscription: Subscription;

  // All products
  isLandingPage: Boolean = false;
  updateAddVehiclesSubscription: Subscription;
  currentUrl: any = '';

  addVehicleMergeSubscription: Subscription;

  searchedBrand =[];
  searchedPartTypes =[];
  searchedParts =[];
  searchedKits =[];
  loadMoreClicked: Boolean = false;
  loadMoreVal = 10;
  public isBrowser : any = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showHideToolbar();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == "Escape") {
      if (this.showAddVehicle) {
        this.resetValuesSearch();
      }
      if (this.showPartTypeSearch) {
        this.toggleSearchPartType();
      }
    }
  }

  constructor(
    private router: Router,
    private sharedService: DataService,
    private cartService: CartService,
    private loginService: LoginSignupService,
    private checkoutDesktopService: CheckoutDesktopService,
    private fb: FormBuilder,
    private _eref: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  )
  {
    this.checkDeviceMode();
    this.isBrowser= isPlatformBrowser(this.platformId);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url;
      if (event.url) {
        if (event.url.includes("error") || (event.urlAfterRedirects && event.urlAfterRedirects.includes("error"))) {
          this.showHeader = false;
        } else {
          this.showHeader = true;
        }
        this.isHome = this.router.url === '/' ? true : false;
        this.isCheckout = (event.url.includes('/checkout') || event.url.includes('/payment')) ? true : false;
        this.isLandingPage = (event.url.includes('part-type') || event.url.includes('brand') || event.url.includes('category')) ? true : false;
      }
      this.isShowSlider(event.url);
    });
    this.searchForm = this.fb.group({
      searchBar: ['', [Validators.required]],
    });
    this.subscriptionFunctions();
  }

  isShowSlider (currentUrl) {
      if(currentUrl == '/'){
        this.showSlider = true;
      }
      else if(currentUrl != '/' && !this.isMobile){
        this.showSlider = true;
      }
      else {this.showSlider = false}
  }

  subscriptionFunctions(): void {
    // Cart Service Subscription trigger
    this.cartUpdateSubscription = this.cartService.cartUpdateTrigger$.subscribe(() => {
      this.loadCartList();
    });
    this.cartDetailTriggerSubscription = this.cartService.cartDetailsUpdated.subscribe((resp) => {
      this.loadCartList_init(this.cartService.cartDetails)
    });
    this.cartMergeSubscription = this.loginService.cartMergeTrigger$.subscribe(() => {
      this.mergeCart();
    });
    this.addVehicleMergeSubscription = this.loginService.addVehicleMergeTrigger$.subscribe(() => {
      this.mergeAddedVehicle();
    })
    this.cartResetSubscription = this.loginService.cartResetTrigger$.subscribe(() => {
      this.itemCount = 0;
      this.itemsInCart = [];
    });

    // Placeorder Subscription trigger
    this.placeOrderTriggerSubscription = this.checkoutDesktopService.placeOrderTrigger.subscribe(() => {
      this.loadCartList();
    });


    // Login Subscription triggers

    this.performLoginTriggerSubscription = this.sharedService.performLoginTrigger.subscribe(params => {
      this.login(params);
    });
    this.userLoggedInTriggerSubscription = this.loginService.userLoggedInTrigger.subscribe((data) => {
      if (data && Object.keys(data).length > 0) {
        this.is_Logged = true;
        this.userData = data;
        this.loadCartList();
      }
    });

    this.userLoggedOutTriggerSubscription = this.loginService.userLoggedOutTrigger.subscribe(() => {
      this.is_Logged = false;
      this.userData = null;
    });
    // Catergories Data Subscription
    
    this.showOrderStatusTriggerSubsription = this.sharedService.showOrderStatusTrigger.subscribe(event => {
      this.userAccess.showOrderStatusForm();
    });

    // Header Search Changes

    this.searchForm.get('searchBar').valueChanges.pipe(
      filter(data => {
        if (data.trim().length >= 3) {
          return data.trim().length >= 3
        } else {
          this.showSearchDrop = false;
          this.showLoadingSearch = false;
          this.searchResult = '';
          return;
        }
      }),
      debounceTime(1000),
      switchMap((id: string) => {
        this.showLoadingSearch = true;
        this.showSearchDrop = false;
        return id ? this.sharedService.getHeaderSearch(id) : of([]);
      })
      ).subscribe((response: any) => {
        const { status, data } = response;
        if (isPlatformBrowser(this.platformId))
        { 
          if (status == 20 && Object.keys(data).length > 0) {
            this.searchedBrand = data.brands && data.brands.length > 0 ? data.brands : [];
            this.searchedPartTypes = data.part_types && data.part_types.length > 0 ? data.part_types : [];
            this.searchedParts = data.parts && data.parts.length > 0 ? data.parts : [];
            this.searchedKits = data.kits && data.kits.length > 0 ? data.kits : [];
            if (this.searchedPartTypes.length == 0 && this.searchedBrand.length == 0 && this.searchedParts.length == 0 && this.searchedKits.length == 0) {  
            this.searchResult = ''
          } else {
            this.searchResult = {
              brands: [],
              part_types: [],
              parts: [],
              kits: [],
            }
          }
          if (this.searchedParts && this.searchedParts.length > 0) {
            this.searchResult['parts'] = this.searchedParts.slice(0, this.loadMoreVal);
          }
          if (this.searchedKits && this.searchedKits.length > 0) {
            this.searchResult['kits'] = this.searchedKits.slice(0, this.loadMoreVal);
          }
          if (this.searchedBrand && this.searchedBrand.length > 0) {
            this.searchedBrand.forEach(element => {
              element['redirect_url'] = 'brand/' + element.id + '/' + element.name;
            });
            this.setRedirectionUrlParts(this.searchedBrand, 1);
          }
          if (this.searchedPartTypes && this.searchedPartTypes.length > 0) {
            this.setRedirectionUrlParts(this.searchedPartTypes, 2);
          }
        } else {
          this.searchResult = '';
        }
        this.showLoadingSearch = false;
        this.showSearchDrop = true;
          
        }
    }, err => {
      this.searchResult = '';
      this.showSearchDrop = true;
      this.showLoadingSearch = false;
    })

    // Add Vehicle Search

    this.yearMakeModelDataSubscription = this.sharedService.yearMakeModelData.subscribe((data) => {
      if (data && Object.keys(data).length > 0) {
        this.setVehicleSearchFilter(data);
      }
    })

    // Show / Hide Vehicle Search

    this.showAddVehicleDataSubscription = this.sharedService.showAddVehicleData.subscribe((data) => {
      if (data) {
        this.vehicleSelectionIndex = data;
        this.openAddVehicle();
      }
    })

    // PartType Search Selection

    this.emitPartTypeDataSubscription = this.sharedService.emitPartTypeData.subscribe((data) => {
      if (data && Object.keys(data).length > 0) {
        if (data.status && data.array.length > 0) {
          this.toggleSearchPartType();
          this.searchPartTypes = data.array.sort((a, b) => { return a.name.localeCompare(b.name); });
          this.localsearchPartTypes = JSON.parse(JSON.stringify(this.searchPartTypes));
          this.partSearchData = data.searchData;
          this.partypeSearch = '';
        } else {
          if (data.index == 0) {
            this.navigateToSearch();
          }

        }
      }
    })

    this.sharedService.floatingToolbarDestroy.subscribe((data) => {
      if (data && Object.keys(data).length > 0) {
        this.passed = data.satus;
      }
    })

    // preSelectMakeYearModel

    // this.preSelectMakeYearModelSubscription = this.sharedService.preSelectMakeYearModel.subscribe((data) => {
    //   if (data && Object.keys(data).length > 0) {
    //     this.preSelectYearMakeModelFunction(data);
    //   }
    // })

    this.updateAddVehiclesSubscription = this.sharedService.updateAddVehicles.subscribe((data) => {
      if (data && Object.keys(data).length > 0) {
        this.getParams['year'] = data.year_id;
        this.getParams['make'] = data.make_id;
        this.getParams['model'] = data.model_id;
        this.year = data.year_id //{ id: data.year_id, year: data.yearname };
        this.make = data.make_id //{ id: data.make_id, name: data.makename };
        this.model = data.model_id //{ id: data.model_id, name: data.modelname };
        this.postAddedVehicle();
      }
    })

  }


  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.sharedService.callGetYearMakeModel();
      this.checkValidLogin();
      this.getAddedVehicle();
      this.showHideToolbar();
      if(!this.isMobile){
        this.getPilotReviews();
        this.getUserReviews();
      }
    }
  }

  callGetYearMakeModel(){
    this.sharedService.callGetYearMakeModel();
  }

  getUserReviews ()
  { 
    this.sharedService.getUserReviews(this.limit, this.offset).subscribe((res: any) =>
    {
      if (res.status == 20)
      {
        if (isPlatformBrowser(this.platformId))
        {
          if (res.data.customer_reviews && res.data.customer_reviews.length > 0)
          { 
            this.reviews = res.data.customer_reviews;
            this.customerReviews = res.data;
            this.avgRating = parseInt(res.data.rating);
            this.limit += this.limit;
            this.sharedService.siteReviewsData.next(res)
          }
        }
      }
    })
  }

  getPilotReviews ()
  {
    this.pilotReviews = PILOT_REVIEWS;
    this.pilotReviewsCount = this.pilotReviews.length;
  }

  
  // Floating Toolbar

  showHideToolbar(): void {
    if (window.innerWidth < 748) {
      this.passed = false;
      return;
    }
    const element = document.getElementById('home-pdropdown-container-Id');
    if (element) {
      const rect = element.getBoundingClientRect();
      const scrollPosition = window.pageYOffset;
      this.passed = scrollPosition >= (rect.top + window.scrollY + rect.height) ? true : false;
      if (!this.passed) {
        this.searchForm.controls.searchBar.setValue(this.searchForm.controls['searchBar'].value, { emitEvent: false });
      }
      this.sharedService.floatingToolbar.next({ status: this.passed });
    }
  }


  enterKeySelection(id, styleclass) {
    this.sharedService.enterKeySelection(id, styleclass);
  }

  clearPDropdown(id, index, value) {
    this.sharedService.resetAfterSelection(id);
    switch(index){
      case 1:
        this.year = this.yearArray.find(x => x.id == value);
        this.onHide(index, this.year);
        break;
      case 2:
        this.make = this.makeArray.find(x => x.id == value);
        this.onHide(index, this.make);
        break;
      case 3:
        this.model = this.modelArray.find(x => x.id == value);
        this.onHide(index, this.model);
        break;
      default:
        break;
    }
  }


  redirectToPage(element): string {
    const a = document.createElement("a");
    a.href = element;
    return a['pathname'];
  }

  setRedirectionUrlParts(array, status): void {
    let concatedarray = array;
    for (let i = 0, len = concatedarray.length; i < len; ++i) {
      concatedarray[i]['redirect_url'] = this.redirectToPage(concatedarray[i]['redirect_url']);
    }
          
    if (isPlatformBrowser(this.platformId))
    { 
      if (status == 2) {
        this.searchResult['part_types'] = concatedarray.slice(0, this.loadMoreVal);
      }
      else {
        this.searchResult['brands'] = concatedarray.slice(0, this.loadMoreVal);
      }
      
    }

  }

  checkValidLogin(): void {
    if (localStorage && (this.isBrowser || this.isMobile)) {
      this.loginService.checkTokenValid().subscribe(
        response => {
          if (response["status"] == 20) {
            const user = localStorage.getItem("user");
            if (user) {
              this.is_Logged = true;
              this.userData = JSON.parse(user);
            }
          }
        },
        error => {
          const accessToken = localStorage.getItem("access_token");
          if (error.status == 401 && accessToken) {
            this.loginService.performLogout();
          }
        }
      );
      this.cartService.getCartItems_local();
    }
  }


  loadCartList(): void {
    this.cartService.setCartLoading(true);
    if(this.isBrowser || this.isMobile){
      this.cartService.getCartItems().subscribe(
        response => {
          if (response["status"] == "20") {
            this.cartService.updateCartItems_local(response);
            this.itemsInCart = response["data"]["response"] || [];
            this.itemCount = this.itemsInCart.reduce((a, b) => a + (b["quantity"] || 0), 0);
            this.cartService.publishCartCount(this.itemsInCart.reduce((a, b) => a + (b["quantity"] || 0), 0));
          } else {
            this.itemsInCart = [];
            this.itemCount = 0;
            this.cartService.updateCartItems_local(null);
            this.cartService.publishCartCount(0);
          }
        },
        error => {
          this.itemsInCart = [];
          this.itemCount = 0;
          this.cartService.updateCartItems_local(null);
          this.cartService.publishCartCount(0);
        }
      );
    }

    
  }

  loadCartList_init(response): void {
    if (response && response["status"] == "20") {
      this.itemsInCart = response["data"]["response"] || [];
      this.itemCount = this.itemsInCart.reduce((a, b) => a + (b["quantity"] || 0), 0);
      this.cartService.publishCartCount(this.itemsInCart.reduce((a, b) => a + (b["quantity"] || 0), 0));
    } else {
      this.itemsInCart = [];
      this.itemCount = 0;
      this.cartService.publishCartCount(0);
    }
  }

  mergeCart(): void {
    let items = this.itemsInCart.map(item => item.id);
    this.cartService.mergeCart(items);
  }

  calculateTopMob(element): Number {
    if (element) {
      return element.getBoundingClientRect().top + 30;
    } else {
      return 0;
    }
  }

  toggleAccount($event): void {
    $event.stopPropagation();
    if (this.is_Logged)
    {
      this.showAccountDrop = !this.showAccountDrop;
    }
    else
    {
      this.login();
    }
  }

  closeDropdowns(event): void {
    if (!this._eref.nativeElement.contains(event.target) && !this.loadMoreClicked) {
      this.showAccountDrop = false;
      this.closeSearchDropdown();
    }
    this.loadMoreClicked = false;
  }

  closeSearchDropdown(): void {
    this.showSearchDrop = false;
    this.showLoadingSearch = false;
  }

  resetSearch(): void {
    this.searchForm.controls.searchBar.setValue('', { emitEvent: false });
    this.searchResult = '';
    this.sharedService.resetFilter();
  }

  triggerBoth(): void {
    this.closeSearchDropdown();
    this.resetSearch();
  }

  submitSearch() {
    this.searchForm.controls.searchBar.setValue(this.searchForm.controls['searchBar'].value);
  }


  checkDeviceMode(): void {
    if (window.innerWidth < 748) {
      this.versionStatus = 0;  // Mobile
      this.toggleTab = false;
      this.showAccountDrop = false;
      this.isMobile = true;
    } else if (window.innerWidth >= 748 && window.innerWidth <= 1024) {
      this.versionStatus = 1;  // Tablet
      this.toggleMobile = this.showAddVehicleMob = false;
      this.showAccountDrop = false;
      this.toggleExtraMenus = [false, false];
      this.isMobile = true;
    } else {
      this.versionStatus = 2;   // Desktop
      this.toggleMobile = this.toggleTab = this.showAddVehicleMob = false;
      this.toggleExtraMenus = [false, false];
      this.isMobile = false;
    }
  }

  toggleTabMenu(): void {
    this.toggleTab = !this.toggleTab;
  }

  calculateHeight(): Number {
    return window.innerHeight - 71;
  }


  login(params?): void {
    if (params) {
      this.userAccess.showLoginForm(params[0], params[1]);
    } else {
      this.userAccess.showLoginForm();
    }
  }

  logout(): void {
    this.loginService.performLogout();
    this.cartService.updateCartItems_local(null);
    this.vehicleSelectedId = '';
    this.vehicleData = [];
    this.selectedVehicleName = '';
    this.sharedService.setVehicleData.next({ data: [], id: '' });
    this.sharedService.setAddVehicleData(null);
  }

  openChat() {
    this.sharedService.emitChatPopupTrigger();
  }

  navigateToDashboard(): void {
    this.router.navigate(["/dashboard/orders"]);
  }

  toggleMobileMenu(): void {
    this.toggleMobile = !this.toggleMobile;
  }

  goToOrderStatus(): void {
    this.toggleMobileMenu()
    this.userAccess.showOrderStatusForm();
  }

  enableExtraMenu(index): void {
    this.toggleExtraMenus[index] = !this.toggleExtraMenus[index];
  }




  // Add Vehicle

  toggleSearchPartType() {
    this.showPartTypeSearch = !this.showPartTypeSearch;
    if (this.showPartTypeSearch) {
      this.document.body.classList.add('body-overflow-hidden-header');
    } else {
      this.document.body.classList.remove('body-overflow-hidden-header');
    }
  }

  submitPartTypeSearch(): void {
    const data = JSON.parse(JSON.stringify(this.localsearchPartTypes));
    if (this.partypeSearch && this.partypeSearch.trim().length > 0) {
      this.searchPartTypes = data.filter((x) => {
        return x['name'].toLowerCase().includes(this.partypeSearch.trim().toString().toLowerCase())
      }).sort((a, b) => { return a.name.localeCompare(b.name); });
    } else {
      this.searchPartTypes = data.sort((a, b) => { return a.name.localeCompare(b.name); });
    }
  }



  desktopToggleVehicleAddOpen(): void {
    this.toggleVehicleAdd = !this.toggleVehicleAdd;
  }
  
  toggleAddVehicle(): void {
    this.toggleMobile = !this.toggleMobile;
    this.showAddVehicleMob = !this.showAddVehicleMob;
  }



  openAddVehicle(status?): void {
    this.callGetYearMakeModel();
    if (status) {
      this.vehicleSelectionIndex = status;
    }
    this.showAddVehicle = !this.showAddVehicle;
    if (this.showAddVehicle) {
      this.document.body.classList.add('body-overflow-hidden-header');
    } else {
      this.document.body.classList.remove('body-overflow-hidden-header');
    }
    this.selectErr = '';
  }

  resetValuesSearch(): void {
    this.openAddVehicle();
    this.year = {};
    this.yearObj = {};
    this.make = {};
    this.makeObj = {};
    this.model = {};
    this.modelObj = {};
    this.makeArray = [];
    this.modelArray = [];
    this.getParams = { year: '', make: '', model: '' };
  }

  disableSearch(): boolean {
    if (!this.modelObj || !this.yearObj || !this.makeObj || Object.keys(this.makeObj).length == 0 || Object.keys(this.yearObj).length == 0 || Object.keys(this.modelObj).length == 0) {
      return true;
    } else {
      return false;
    }
  }


  setVehicleSearchFilter(data): void {
    if (data['datasection'] !== 1 && data['datasection'] > 0) {
      return;
    }
    const index = data['index'];
    const status = data['status'];
    const array = data['array'];
    const showDrop = data['showDrop'];
    const makeDrop = document.getElementById('make-add-vehicle-id');
    const modelDrop = document.getElementById('model-add-vehicle-id');
    if (status == 1) {
      switch (index) {
        case 0: this.yearArray = array.sort((a, b) => { return b['year'] - a['year']; });
          if (this.yearArray.length == 0) {
            this.selectErr = 'No Years Found';
          }
          break;
        case 1: this.makeArray = array;
          if (this.makeArray.length > 0 && makeDrop && showDrop) {
            setTimeout(() => {
              (makeDrop.querySelector('.home-search-drop-header') as any).click();
            });

          }
          if (this.makeArray.length == 0) {
            this.selectErr = 'No Makes Found';
          }
          break;
        case 2: this.modelArray = array;
          if (this.modelArray.length > 0 && modelDrop && showDrop) {
            setTimeout(() => {
              (modelDrop.querySelector('.home-search-drop-header') as any).click();
            });
          }
          if (this.modelArray.length == 0) {
            this.selectErr = 'No Models Found';
          }
          break;
      }

    }
    if (status == 0) {
      switch (index) {
        case 0: this.yearArray = []; this.selectErr = 'No Years Found';
          break;
        case 1: this.makeArray = []; this.selectErr = 'No Makes Found';
          break;
        case 2: this.modelArray = []; this.selectErr = 'No Models Found';
          break;
      }

    }
    if (status == -1) {
      this.yearArray = this.makeArray = this.modelArray = [];
      this.selectErr = 'No Data Found'
    }
    this.headerpage = {
      yearLoading: false,
      makeLoading: false,
      modelLoading: false,
      yearDisabled: false,
      makeDisabled: false,
      modelDisabled: false,
    };
  }

  onHide(index, value, dropdown?): void {
    if (!value || Object.keys(value).length == 0) {
      return;
    }
    this.selectErr = '';
    const paramValue = this.getParams[index === 1 ? 'year' : index === 2 ? 'make' : 'model'];
    if (!paramValue) {
      this.setDropDownValues(index, value, dropdown);
    } else {
      if (value && paramValue && value['id'] === paramValue) {
        return;
      } else {
        this.setDropDownValues(index, value, dropdown);
      }
    }
  }

  setDropDownValues(index, value, dropdown?): void {
    switch (index) {
      case 1:
        this.headerpage.makeLoading = true;
        this.headerpage.yearDisabled = true;
        this.yearObj = value;
        this.year = value.id;
        this.make = this.model = {};
        this.getParams['year'] = value['id'];
        this.getParams['make'] = this.getParams['model'] = '';
        this.makeArray = this.modelArray = [];
        break;
      case 2:
        this.headerpage.modelLoading = true;
        this.headerpage.yearDisabled = true;
        this.headerpage.makeDisabled = true;
        this.makeObj = value;
        this.make = value.id;
        this.model = {};
        this.getParams['make'] = value['id'];
        this.getParams['model'] = '';
        this.modelArray = [];
        break;
      case 3:
        this.modelObj = value;
        this.model = value.id;
        this.getParams['model'] = value['id'];
        break;
    }
    if (index !== 3) {
      this.sharedService.getYearMakeModel(index, 1, dropdown ? false : true, this.getParams['year'], this.getParams['make'], this.getParams['model']);
    }
    // this.addVehicleToLocal();

  }

  addVehicleToLocal() {
    if (this.disableSearch()) {
      return;
    }  
    if (this.vehicleSelectionIndex == 1) {
      this.postAddedVehicle();
      this.resetValuesSearch();
    }
    else
    {
      this.showAddVehicle = !this.showAddVehicle;
      if (this.showAddVehicle) {
        this.document.body.classList.add('body-overflow-hidden-header');
      }
      else
      {
        this.document.body.classList.remove('body-overflow-hidden-header');
      }
      const searchData = {
        year_id: this.getParams['year'],
        make_id: this.getParams['make'],
        model_id: this.getParams['model'],
        yearname: this.year['year'],
        makename: this.make['name'],
        modelname: this.model['name'],
      };
      const trimText = (text) => text.replace(/\s+/g, '-').toLowerCase();
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      {
        this.router.navigate([`all-products/${trimText(this.year['year'])}/${trimText(this.make['name'])}/${trimText(this.model['name'])}`])
      }
      );

    }
  }

  navigateToSearch() {
    this.sharedService.setSearchLocalValues(this.year, this.make, this.model, '');
    this.router.navigate([`search`],
      {
        queryParams: {
          year: this.getParams['year'], yearname: this.year['year'],
          make: this.getParams['make'],
          makename: this.make['name'], model: this.getParams['model'],
          modelname: this.model['name'],
        }
      }
    );
    this.resetValuesSearch();
    this.getAddedVehicle();
  }

  navigateToSearchWithParts(items): void {
    this.toggleSearchPartType();
    const make = { id: this.partSearchData.make_id, name: this.partSearchData.makename }
    const model = { id: this.partSearchData.model_id, name: this.partSearchData.modelname }
    const year = { id: this.partSearchData.year_id, name: this.partSearchData.yearname }
    this.sharedService.setSearchLocalValues(year, make, model, items);
    this.router.navigate([`search`],
      {
        queryParams: {
          year: this.partSearchData.year_id, yearname: this.partSearchData.yearname, make: this.partSearchData.make_id, makename: this.partSearchData.makename, model: this.partSearchData.model_id, modelname: this.partSearchData.modelname,
          part: items.id, partname: items.name, parturl: this.sharedService.redirectToPage(items.redirect_url)
        }
      }
    );
  }


  // Add Vehicle

  getAddedVehicle(): void {
    const values = { data: [], id: '',makeArr: [],modelArr : [] };
    this.sharedService.getAddedVehicleData().subscribe((res: any) => {
      if (res && res.status == 20 && res.data && Object.keys(res.data).length > 0) {
        if (!localStorage.getItem("access_token")) {
          localStorage.setItem('add_vehicle_session_id', JSON.stringify(res.data.session_id));
        } else {
          localStorage.removeItem('add_vehicle_session_id');
        }
        this.vehicleData = res.data.response && res.data.response.length > 0 ? res.data.response : null;
        if (res.data.response && res.data.response.length > 0) {
          this.vehicleData.forEach((x) => {
            x.uniqueId = (x.year_id + '-' + x.make_id + '-' + x.model_id).trim();
          });
          const selectedVehicle = this.vehicleData.filter(x => x.is_selected)[0]
          values.data = this.vehicleData;
          values.id = this.vehicleSelectedId = selectedVehicle.id;
          this.selectedVehicleName = selectedVehicle.year + ' ' + selectedVehicle.make + ' ' + selectedVehicle.model;
          this.sharedService.setAddVehicleData(selectedVehicle);
        }
      } else {
        let session_id = localStorage.getItem("add_vehicle_session_id") ? JSON.parse(localStorage.getItem("add_vehicle_session_id")) : null;
        if(session_id){
          this.vehicleSelectedId = '';
          this.vehicleData = [];
          this.selectedVehicleName = '';
          this.sharedService.setAddVehicleData(null);
        }
        this.sharedService.setVehicleData.next(values);
      }
    }, err => {
      this.vehicleSelectedId = '';
      this.vehicleData = [];
      this.selectedVehicleName = '';
      this.sharedService.setAddVehicleData(null);
      this.sharedService.setVehicleData.next(values);
    })
  }

  postAddedVehicle() {
    const uniqueId = (this.year + '-' + this.make + '-' + this.model).trim()
    if (this.checkIsYMKAlreadyAdded(uniqueId)) {
      return;
    }
    this.sharedService.postAddedVehicleData(this.year, this.make, this.model).subscribe((res: any) => {
      if (res.status == 20 && res.data && Object.keys(res.data).length > 0) {
        this.selectedVehicleName = res.data.year + ' ' + res.data.make + ' ' + res.data.model;
        if (!localStorage.getItem("access_token")) {
          localStorage.setItem('add_vehicle_session_id', JSON.stringify(res.data.session_id));
        } else {
          localStorage.removeItem('add_vehicle_session_id');
        }
        this.getAddedVehicle();
      } else {
        this.selectedVehicleName = '';
      }
    })
  }

  checkIsYMKAlreadyAdded(uniqueId): Boolean {
    let found = false;
    if (this.vehicleData.length > 0) {
      const uniq = this.vehicleData.filter(x => x.uniqueId == uniqueId);
      found = uniq && uniq.length > 0 ? true : false;
    } else {
      found = false;
    }
    return found;
  }


  changeMyVehicle(items): void {
    this.vehicleSelectedId = items.id;
    if (this.vehicleSelectedId) {

      const errorcase = () => {
        const data = this.vehicleData.filter(x => x.id === this.vehicleSelectedId)[0];
        this.sharedService.setAddVehicleData(data);
        this.selectedVehicleName = data.year.year + ' ' + data.make.name + ' ' + data.model.name;
        this.sharedService.setVehicleData.next({ data: this.vehicleData, id: this.vehicleSelectedId });
        if (this.currentUrl.includes('all-products')) {
          navigateAllProducts();
        }
      };

      const navigateAllProducts = () => {
        const trimText = (text) => text.replace(/\s+/g, '-').toLowerCase()
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate([`all-products/${trimText(items.year)}/${trimText(items.make)}/${trimText(items.model)}`])
        );
      }

      this.sharedService.putAddedVehicle(this.vehicleSelectedId).subscribe((res: any) => {
        if (res.status == 20) {
          this.getAddedVehicle();
          if (this.currentUrl.includes('all-products')) {
            navigateAllProducts();
          }
        } else {
          errorcase();
        }
      }, err => {
        errorcase();
      })
    }
  }

  deleteEntires(items): void {
    const errorcase = () => {
      this.vehicleData.splice(this.vehicleData.findIndex(x => x.id == items.id), 1);
    };
    this.sharedService.deleteAddedVehicle(items.id).subscribe((res: any) => {
      if (res.status == 20) {
        this.getAddedVehicle();
      } else {
        errorcase();
      }
    }, err => {
      errorcase();
    })
  }

  clearAll() {
    const clearOut = () => {
      this.vehicleSelectedId = '';
      this.vehicleData = [];
      this.selectedVehicleName = '';
      this.sharedService.setVehicleData.next({ data: [], id: '' });
      this.sharedService.setAddVehicleData(null);
    }
    this.sharedService.clearAllAddedVehicle().subscribe((res: any) => {
      if (res.status == 20) {
        clearOut();
      } else {
        clearOut();
      }
    }, err => {
      clearOut();
    })

  }

  mergeAddedVehicle() {
    let ids = [];
    if (this.vehicleData && this.vehicleData.length > 0) {
      ids = this.vehicleData.map(x => x.id);
    }
    this.sharedService.mergeAddedVehicle(ids).subscribe((res: any) => {
      localStorage.removeItem('add_vehicle_session_id');
      this.getAddedVehicle();
    }, err => {
      this.getAddedVehicle();
    })
  }

  checkOnSearchFocus (): void
  {
    if (this.searchForm.get('searchBar').value && this.searchForm.get('searchBar').value.trim().length > 3 || this.searchtext.length > 3) {
      this.showSearchDrop = true;
    }
  }

  preSelectYearMakeModelFunction(data): void {
    this.vehicleSelectionIndex = data.index;
    this.resetValuesSearch();
    if (data.type == "apyear") {
      const yearFilter = data.breadCrumbsArray.filter(x => x.type == data.type)[0]
      this.onHide(1, { id: yearFilter.id, year: yearFilter.name }, true);
    }
    if (data.type == "apmake" || data.type == "apmodel") {
      const yearFilter = data.breadCrumbsArray.filter(x => x.type == "apyear")[0]
      this.onHide(1, { id: yearFilter.id, year: yearFilter.name }, true);
      const makeFilter = data.breadCrumbsArray.filter(x => x.type == 'apmake')[0];
      this.onHide(2, { id: makeFilter.id, name: makeFilter.name }, true);
    }
    this.openPdropdown(data.type == "apyear" ? 'year-add-vehicle-id' : data.type == "apmodel" ? 'model-add-vehicle-id' : 'make-add-vehicle-id');

  }


  openPdropdown(id): void {
    const waiting = () => {
      const drop = document.getElementById(id);
      if (drop) {
        setTimeout(() => {
          (drop.querySelector('.home-search-drop-header') as any).click();
        }, 800);
      } else {
        setTimeout(waiting, 100);
      }
    };
    waiting();
  }

  loadSearchData(searchedItem, loadType){
    this.loadMoreClicked = true;
    if (isPlatformBrowser(this.platformId))
    { 
      switch (loadType) {
        case 'parts':
          let spiceLengthParts = this.searchResult['parts'].length + this.loadMoreVal;
          this.searchResult['parts'] = searchedItem.slice(0, spiceLengthParts);
          break;
        
        case 'kits':
          let spiceLengthKits = this.searchResult['kits'].length + this.loadMoreVal;
          this.searchResult['kits'] = searchedItem.slice(0, spiceLengthKits);
          break;
        
        case 'partTypes':
          let spiceLengthPartTypes = this.searchResult['part_types'].length + this.loadMoreVal;
          this.searchResult['part_types'] = searchedItem.slice(0, spiceLengthPartTypes);
          break;
        
        case 'brands':
          let spiceLengthBrands = this.searchResult['brands'].length + this.loadMoreVal;
          this.searchResult['brands'] = searchedItem.slice(0, spiceLengthBrands);
          break;
      }    
    }
  }

  toggleReviewModal (){
    this.isShowReviewModal = !this.isShowReviewModal;
    this.isShowPilotModal = false;
  }
  togglePilotModal(){
    this.isShowPilotModal = !this.isShowPilotModal;
    this.isShowReviewModal = false;
  }

  onOutsideClick(event: MouseEvent) {
    this.isShowPilotModal = false;
    this.isShowReviewModal = false;
  }

  ngOnDestroy(): void {
    this.cartUpdateSubscription.unsubscribe();
    this.cartDetailTriggerSubscription.unsubscribe();
    this.cartMergeSubscription.unsubscribe();
    this.cartResetSubscription.unsubscribe();
    this.placeOrderTriggerSubscription.unsubscribe();
    this.performLoginTriggerSubscription.unsubscribe();
    this.userLoggedInTriggerSubscription.unsubscribe();
    this.userLoggedOutTriggerSubscription.unsubscribe();
    this.showOrderStatusTriggerSubsription.unsubscribe();
    this.yearMakeModelDataSubscription.unsubscribe();
    this.showAddVehicleDataSubscription.unsubscribe();
    this.emitPartTypeDataSubscription.unsubscribe();
    // this.preSelectMakeYearModelSubscription.unsubscribe();]

    if (this.updateAddVehiclesSubscription) {
      this.updateAddVehiclesSubscription.unsubscribe();
    }
    if (this.addVehicleMergeSubscription) {
      this.addVehicleMergeSubscription.unsubscribe();
    }
  }

}
     