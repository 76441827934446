import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConstants } from "../../../app/app.constants";
import { Subject } from "rxjs";
import { DataService } from "src/app/shared/common-services/data.service";

@Injectable({
  providedIn: "root"
})
export class CartService {
  constructor(private httpClient: HttpClient, private sharedService: DataService) { }

  // Observable string sources
  public cartUpdateTriggerCallSource = new Subject<any>();

  // Observable string streams
  cartUpdateTrigger$ = this.cartUpdateTriggerCallSource.asObservable();

  publishCartCountTrigger: EventEmitter<any> = new EventEmitter<any>();
  cartItemDisplayMessageEvent: EventEmitter<any> = new EventEmitter<any>();
  cartDetailsUpdated: EventEmitter<any> = new EventEmitter<any>();
  cartError: EventEmitter<any> = new EventEmitter<any>();

  cartDetailLoading: boolean = false;
  cartDetails: any;

  getCartItems() {
    let url = AppConstants.baseURL + AppConstants.URL_CART;
    let headers = { "Content-Type": "application/json" };
    let accessToken = localStorage.getItem("access_token");
    let sessionId = localStorage.getItem("session_id") === "undefined" ? null : localStorage.getItem("session_id");

    if (accessToken) {
      headers["Auth"] = `Bearer ${accessToken}`;
    } else {
      url += `?session_id=${sessionId}`;
    }
    return this.httpClient.get(url, { headers: new HttpHeaders(headers) });
  }

  getCartItems_local() {
    this.cartDetailLoading = true;
    let url = AppConstants.baseURL + AppConstants.URL_CART;
    let headers = { "Content-Type": "application/json" };
    let accessToken = localStorage.getItem("access_token");
    let sessionId = localStorage.getItem("session_id") === "undefined" ? null : localStorage.getItem("session_id");

    if (accessToken) {
      headers["Auth"] = `Bearer ${accessToken}`;
    } else {
      url += `?session_id=${sessionId}`;
    }

    this.httpClient.get(url, { headers: new HttpHeaders(headers) }).subscribe(resp => {
      if (resp['status'] == "20") {
        this.cartDetails = JSON.parse(JSON.stringify(resp))
        this.cartDetailsUpdated.emit({ status: true, data: resp });
      } else {
        this.cartError.emit(resp["message"]);
      }
      this.cartDetailLoading = false;
    }, error => {
      this.cartDetailLoading = false;
      this.cartError.emit(error.message);
    });
  }

  updateCartItems_local(data: any) {
    if (data) {
      this.cartDetails = JSON.parse(JSON.stringify(data))
      this.cartDetailLoading = false;
      this.cartDetailsUpdated.emit({ status: true, data: data });
    } else {
      this.cartDetails = null;
      this.cartDetailLoading = false;
      this.cartDetailsUpdated.emit({ status: true, data: null });
    }
  }

  setCartLoading(val: boolean) {
    this.cartDetailLoading = val;
  }

  addToCart(data, callback?, returnError?) {
    let headers = { "Content-Type": "application/json" };
    let accessToken = localStorage.getItem("access_token");
    let sessionId = localStorage.getItem("session_id") === "undefined" ? null : localStorage.getItem("session_id");

    if (accessToken) {
      headers["Auth"] = `Bearer ${accessToken}`;
      data["session_id"] = null;
    } else {
      data["session_id"] = sessionId;
    }

    this.httpClient
      .post(AppConstants.baseURL + AppConstants.URL_CART, data, {
        headers: new HttpHeaders(headers)
      })
      .subscribe(
        response => {
          if (response["status"] == "20") {
            this.addToCartGA(response["data"]);
            if (response["data"] && response["data"]["session_id"]) {
              localStorage.setItem("session_id", response["data"]["session_id"]);
            }
            else {
              if (callback) {
                callback("serverError");
              }
            }
            this.cartUpdateTriggerCallSource.next();
            if (callback) {
              callback(response["message"]);
            }
          } else {
            if (returnError) {
              returnError(response);
            } else {
              callback(response["message"]);
            }
          }
        },
        error => {
          this.cartError.emit(error.message);
        }
      );
  }

  updateCartQuantity(itemId, data, cartIndex) {
    let headers = { "Content-Type": "application/json" };
    let accessToken = localStorage.getItem("access_token");
    let sessionId = localStorage.getItem("session_id") === "undefined" ? null : localStorage.getItem("session_id");

    if (accessToken) {
      headers["Auth"] = `Bearer ${accessToken}`;
      data["session_id"] = null;
    } else {
      data["session_id"] = sessionId;
    }
    this.httpClient
      .put(AppConstants.baseURL + AppConstants.URL_CART + `/${itemId}`, data, {
        headers: new HttpHeaders(headers)
      })
      .subscribe(
        response => {
          if (response["status"] == "20") {
            this.cartUpdateTriggerCallSource.next();
          } else {
            this.cartItemDisplayMessageEvent.emit(cartIndex)
          }
        },
        error => {
          this.cartError.emit(error.message);
        }
      );
  }

  removeFromCart(itemId) {
    let url = AppConstants.baseURL + AppConstants.URL_CART + `/${itemId}`;
    let headers = { "Content-Type": "application/json" };
    let accessToken = localStorage.getItem("access_token");
    let sessionId = localStorage.getItem("session_id") === "undefined" ? null : localStorage.getItem("session_id");

    if (accessToken) {
      headers["Auth"] = `Bearer ${accessToken}`;
    } else {
      url += `?session_id=${sessionId}`;
    }

    this.httpClient
      .delete(url, { headers: new HttpHeaders(headers) })
      .subscribe(
        response => {
          if (response["status"] == "20") {
            this.cartUpdateTriggerCallSource.next();
          } else {
            this.cartError.emit(response['message']);
          }
        },
        error => {
          this.cartError.emit(error.message);
        }
      );
  }

  mergeCart(items) {
    let url = AppConstants.baseURL + AppConstants.URL_MERGE_CART;
    let headers = { "Content-Type": "application/json" };
    let accessToken = localStorage.getItem("access_token");
    let sessionId = localStorage.getItem("session_id") === "undefined" ? null : localStorage.getItem("session_id");
    let data = {
      session_id: sessionId,
      carts: items
    };
    headers["Auth"] = `Bearer ${accessToken}`;
    this.httpClient
      .post(url, data, { headers: new HttpHeaders(headers) })
      .subscribe(
        response => {
          if (response["status"] == "20") {
            this.cartUpdateTriggerCallSource.next({ merge: true });
          } else {
            this.cartError.emit(response['message']);
          }
        },
        error => {
          this.cartError.emit(error.message);
        }
      );
  }

  publishCartCount(cartCount) {
    this.publishCartCountTrigger.emit(cartCount);
  }

  invokeCartUpdateTrigger() {
    this.cartUpdateTriggerCallSource.next();
  }

  getDeliveryEstimates(id, type, data) {
    const { latitude, longitude, isLogged, address_id, zip_code } = data;
    let params, headers;
    params = "?";
    if (id) params += "&item_number=" + id;
    if (isLogged) headers = new HttpHeaders().set("Auth", "Bearer " + localStorage.getItem("access_token"));
    if (latitude && longitude) params += "&latitude=" + latitude + "&longitude=" + longitude;
    if (address_id && address_id != "default") { params += "&address_id=" + address_id }
    if (zip_code) { params += "&zip_code=" + zip_code }
    var url = type ? AppConstants.URL_DELIVERY_DETAILS : AppConstants.URL_KIT_DELIVERY_DETAILS;
    return this.httpClient.get(AppConstants.baseURL + url + params, { headers: headers ? headers : null });
  }

  getGoogleZipResponse(zipcode) {
    return this.httpClient.get(AppConstants.baseURL + `/common_content/get_address_from_zip?zip=${zipcode}`);
    // return this.httpClient.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&components=postal_code:${zipcode}&region=us&sensor=true&key=${AppConstants.GOOGLE_API_KEY}`);
  }


  addToCartGA(data) {
    let prd = [];
    if (data.length) { // array
      data.forEach(item => {
        prd.push({
          name: item.title,
          id: item.id,
          price: item.price,
          brand: item.brand,
          quantity: item.quantity
        });
      });
    } else { // object
      prd.push({
        name: data.title,
        id: data.id,
        price: data.price,
        brand: data.brand,
        quantity: data.quantity,
      });
    }
    this.sharedService.pushGACart(prd);
  }

}