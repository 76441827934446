<div [hidden]="!displayData" >
  <div class="pt-2 pb-0 px-5 slider-section">
    <div class="swiper slider-swipper d-flex w-100 position-relative">
      <button type="button" class="arrows left swiper-button-prev-slider button-slider"
        [ngClass]="{'display-none':versionStatus==0}">
        <span class="left-card-arrow"></span>
      </button>
      <div class="swiper-wrapper d-flex">
        <ng-container *ngFor="let items of searchParts">
          <div class="swiper-slide d-flex flex-column align-items-center home-search-parts-card center">
            <a class="image-container-link" [routerLink]="items.redirect_url" [queryParams]="items.queryParams" (click)="partTypeClick(items)">
              <div class="image-container d-flex justify-content-center">
                <img [src]="items.image_url?items.image_url:'./assets/images/default-make-icon.svg'"
                  [alt]="items.nick_name" loading="lazy" type="image/webp" class="slide-img">
              </div>
              <p class="part-name">{{items.nick_name}}</p>
            </a>
          </div>
        </ng-container>
      </div>
      <button type="button" class="arrows right swiper-button-next-slider button-slider"
        [ngClass]="{'display-none':versionStatus==0}">
        <span class="right-card-arrow"></span>
      </button>
    </div>
  </div>
</div>