import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

// External Libraries --------------------

import { CookieService } from "ngx-cookie-service";

// Component Modules
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderModule } from "./header/core/header.module";
import { LiveChatModule } from "./shared/components/livechat/core/livechat.module";
import { BreadCrumbsModule } from "./shared/components/breadcrumbs/core/breadcrumbs.module";
import { FooterModule } from "./footer/core/footer.module";
import { DropdownModule } from "primeng/dropdown";
import { PartTypeSliderComponent } from "./part-type-slider/part-type-slider.component";
import { LoaderModule } from "./shared/components/loader/core/loader.module";

@NgModule({
  declarations: [
    AppComponent,
    PartTypeSliderComponent
  ],
  imports: [
    HeaderModule,
    FooterModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,    
    LiveChatModule,
    BreadCrumbsModule,
    DropdownModule,
    LoaderModule,
  ],
  providers: [
    CookieService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }