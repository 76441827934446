<div class="resizable-container">
  <app-header-new-design></app-header-new-design>

  <app-part-type-slider [currentRoute]="routerUrl"></app-part-type-slider>
  <div class="auto-buffy-router-outlet">
    <app-breadcrumbs></app-breadcrumbs>
    <router-outlet></router-outlet>
  </div>
  <app-footer-new-design></app-footer-new-design>
  <ng-container *ngIf="isBrowser">
    <app-livechat></app-livechat>
  </ng-container>
</div>