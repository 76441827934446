export class SeoText {

    public static seoDataStored = {};


    public static SeoArray(url) {
        let data = this.seoData.filter(x => url.includes(x.key))[0];
        if (!data || Object.keys(data).length == 0) {
            const splited = url.split('/').pop();
            const titleCase = (str) => {
                let splitStr = str.toLowerCase().split(' ');
                for (let i = 0; i < splitStr.length; i++) {
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                }
                return splitStr.join(' ');
            }
            data = {
                key: url.split('/'),
                title: titleCase(splited.replace(/-/g, ' ')) + '  | AutoBuffy',
                description: titleCase(splited.replace(/-/g, ' ')),
                image: '',
            };
        }
        this.seoDataStored = data;
        return data;
    }

    public static getStored() {
        return this.seoDataStored;
    }


    public static seoData = [
        {
            key: 'air-suspension-system',
            title: 'Air Suspension System | Air Suspension Kits | AutoBuffy',
            description: 'Thinking about switching to an air suspension system? Check out all of the air suspension kits available on AutoBuffy.com. We’ve got the best systems available at great prices.',
            image: '',

        },
        {
            key: 'brake-pads',
            title: 'Front & Rear Brake Pads | AutoBuffy',
            description: 'Squeaky brakes? We have all your brake pad needs covered at AutoBuffy. Whether it’s front or rear brake pads, we’ve got great brands at competitive prices.',
            image: '',


        },
        {
            key: 'brake-rotor',
            title: 'Front & Rear Brakes and Rotors | AutoBuffy',
            description: 'At AutoBuffy, we have any part you might need for your vehicle’s braking system including front brake pads and rotors. We also have rear brakes and rotors available at great prices.',
            image: '',

        },
        {
            key: 'cv-axle-assembly',
            title: 'Front Axle Shaft Replacement | Right/Left Front Axle Shaft | CV Joint Car',
            description: 'AutoBuffy is your online auto parts solution. We have all the parts you need to fix your front drive axle assembly. We have a great selection of parts, whether you’re looking for a CV shaft or a CV joint assembly. Browse our selection!',
            image: '',

        },
        {
            key: 'motor-mount',
            title: 'Torque Strut Mount | Manual Transmission Motor Mounts | Left/Right Side Engine Mount',
            description: 'AutoBuffy has any transmission or engine mounts you need for your auto repair project. No matter if it’s a left side, right side, or a rear transmission mount, we have it. Check out our shipping options, we can get it there as soon as you need it.',
            image: '',
        },
        {
            key: 'shock-absorber',
            title: 'Shock Absorber Parts | Best Rear/Front Car Shock Absorbers',
            description: 'Enjoy a smooth ride with AutoBuffy. If your auto needs a new front or rear shock absorber, place an order with us. We have a wide selection of great shock absorber parts available to buy now.',
            image: '',

        },
        {
            key: 'suspension-ball-joint-kit',
            title: 'Suspension Ball Joint Kit Parts | AutoBuffy',
            description: 'Ball joints are crucial part of your auto’s suspension. A defective ball joint needs to be replaced ASAP. AutoBuffy has a fantastic line of suspension ball joint kit parts available for any vehicle.',
            image: '',

        },
        {
            key: 'suspension-control-arm-link',
            title: 'Suspension Control Arm Link | Sway Bar Links | AutoBuffy',
            description: 'Sway bars and control arm links are vital to the stability of your automobile. They are easy and inexpensive to replace if they go bad. You can find the sway bar links you need at AutoBuffy.',
            image: '',

        },
        {
            key: 'suspension-strut-assembly',
            title: 'Suspension Strut Rear/Front Assembly | AutoBuffy',
            description: 'AutoBuffy has all the parts you need for your vehicle’s suspension including front and rear strut assemblies. We have parts for almost every make and model with great shipping options.',
            image: '',

        },
        {
            key: 'tail-light',
            title: 'Tail Lights & Assembly | AutoBuffy',
            description: 'One of the most overlooked problems with cars are broken tail lights. For your safety, AutoBuffy suggests checking your lights and tail light assembly. If they need replacement, we have your back',
            image: '',

        },
        {
            key: 'wheel-hub-assembly-kit',
            title: 'Wheel Hub Assembly Kit | AutoBuffy',
            description: 'Your wheel hub assembly is a small but key component in your auto’s steering system. Check out a wheel hub assembly kit at AutoBuffy to keep control of your vehicle and stay safe on the road.',
            image: '',

        },
    ];



}