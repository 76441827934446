import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';
import { SidebarModule } from 'primeng/sidebar';
import { LoginSignUpModule } from 'src/app/login-signup/core/login-signup.module';
import { LoaderModule } from 'src/app/shared/components/loader/core/loader.module';
import { PopupModule } from 'src/app/shared/components/popup/core/popup.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { HeaderNewDesignComponent } from 'src/app/header-new-design/header-new-design.component';
import { FooterNewDesignComponent } from 'src/app/footer-new-design/footer-new-design.component';
import { RatingModule } from 'primeng/rating';
import { NgxPictureModule, CLOUDINARY_CONFIG} from 'ngx-picture';
import { PanelModule } from 'primeng/panel';

@NgModule({
  declarations: [
    HeaderNewDesignComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LoaderModule,
    RouterModule,
    ReactiveFormsModule,
    SidebarModule,
    DropdownModule,
    LoginSignUpModule,
    PopupModule,
    DirectivesModule,
    NgxPictureModule.forRoot(CLOUDINARY_CONFIG),
    RatingModule,
    PanelModule
  ],
  exports: [
    HeaderNewDesignComponent
  ]
})
export class HeaderModule { }
