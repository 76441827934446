import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";


@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LoaderComponent implements OnInit {
  @Input() width: number = 50;
  @Input() height: number = 50;


  constructor() {

  }

  ngOnInit() {

  }


}
