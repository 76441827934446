import { environment } from "./../environments/environment";

export class AppConstants {
  public static isLogged = false;

  public static get baseURL(): string {
    // return "https://admin.baiskoafu.com/api/web-portal/v1/"; // Production
    // return "http://35.154.149.200/api/web-portal/v1/";
    // return "http://10.9.8.99:3000/api/v1/user/"; // Alaka (Local)
    // return "http://10.9.8.103:3000/api/v1/user/"; // Renju (Local)
    // return "http://10.9.8.66:3000/api/v1/user/"; // Anoop (Local)

    // return "https://autobuffy-staging.qburst.build/api/v1/user/"; //Staging (secured)
    return environment.baseUrl;
  }
  
  public static get redirectURL(): string{
    return environment.redirectUrl;
  }

  public static FACEBOOK_APP_ID = environment.FACEBOOK_APP_ID;
  public static STRIPE_PUBLIC_KEY = environment.STRIPE_PUBLIC_KEY;
  public static GPAY_ENVIRONMENT = environment.GPAY_ENVIRONMENT;
  public static GPAY_MERCHANT = environment.GPAY_MERCHANT_ID;
  // public static GOOGLE_API_KEY = environment.GOOGLE_API_KEY;
  public static GENERIC_MESSAGE = "Couldn't connect to server. Please try again later.";
  public static INVALID_EMAIL = "Please enter a valid email.";
  public static INVALID_EMAIL_SUBSCRIPTION = "Please enter a valid email address to subscribe.";
  public static INVALID_FILE_FORMAT = "Invalid file format";
  public static PASSWORD_REQUIRED = "Password required.";
  public static EMAIL_REQUIRED = "Email required.";
  public static FB_AUTHENTICATION_FAILED = "Facebook authentication failed. Please try again.";
  public static PASSWORD_TOO_SHORT = "Password should be atleast six characters.";
  public static PASSWORD_TOO_LONG = "Password too long (maximum 16 characters).";
  public static PASSWORDS_MUST_MATCH = "Passwords must match.";
  public static FILL_MANDATORY_FIELDS_MSG = "Please fill all mandatory fields";
  public static FILL_MANDATORY_FIELDS_MSG_FOR_DELETE = "Please select atleast one item";
  public static RESET_PASSWORD_MAIL_SENT = "Please check your inbox. We've sent a password reset link to your email.";
  public static RESET_PASSWORD_COMPLETE = "Password has been reset. Please login with the new password.";
  public static RESET_PASSWORD_LINK_EXPIRED = "This password reset link has expired.";
  public static IMAGE_SIZE_LIMIT_MB = 10.0;
  public static IMAGE_SIZE_LIMIT_EXCEEDED = "Image size should not be larger than 10 MB";
  public static PDF_SIZE_LIMIT_MB = 10.0;
  public static PDF_SIZE_LIMIT_EXCEEDED = "PDF size should not be larger than 10 MB";
  public static PAGE_COUNT = 10;
  public static REVIEWS_PAGE_COUNT = 3;
  public static CART_RETRIEVE_ERROR = "Failed to get cart details. Please try again.";
  public static CART_DROPDOWN_LIMIT = 5;
  public static USER_LOGIN_PROMPT_TO_SEE_ORDERS = "Please login to see your orders.";
  public static CHECKOUT_FAILED_OUT_OF_STOCK = "Please remove out of stock items and try again.";
  public static CHECKOUT_FAILED_TIMED_OUT_MESSAGER =`Your payment is taking longer than anticipated to process! <p>Please wait and check your email for any updates while we analyze the case.</p> <p>We apologize for the inconvenience caused! </p><p>If you need further assistance, don't hesitate to reach out to our support team at 888-477-2530. Thank you for your understanding.</p>`

  public static FILTER_KIT_KEYWORD = "KIT";
  public static FILTER_PART_KEYWORD = "PART";
  public static FILTER_ALL_TYPE = "ALL";

  public static PRODUCT_LIST_SORT_OPTIONS = [{ label: "Lowest price", value: "PRICE_ASC" }, { label: "Highest price", value: "PRICE_DESC" }, { label: "Rating", value: "RATING" }];

  public static PRODUCT_LIST_LIMIT = 10;

  public static LANDING_FILTER_HEADING_1 = "Tell Us What You Drive and We'll Show You What Fits.";
  public static LANDING_FILTER_HEADING_MOB = "Tell Us What You Drive";
  public static LANDING_TYPE_CATEGORY = "CATEGORY";
  public static LANDING_TYPE_PART_TYPE = "PART_TYPE";
  public static LANDING_TYPE_MAKE_MODEL_PART = "MAKE_MODEL_PART_TYPE";
  public static LANDING_TYPE_MAKE_PART = "MAKE_PART_TYPE";

  public static LOCATION_DENIED_FREE_DELIVERY_MSG = "Delivery Approximately 2-4 days for FREE.";
  public static LOCATION_DENIED_EXPEDITED_DELIVERY_MSG = "Delivery Approximately 1-2 days with expedited shipping.";
  public static LOCATION_ALLOWED_FREE_DELIVERY_MSG = "Delivery Approximately 3-5 days for FREE.";
  public static LOCATION_ALLOWED_EXPEDITED_DELIVERY_MSG = "Delivery Approximately 1-2 days with expedited shipping.";

  public static ORDER_BY_TODAY_MSG = "Order must be placed by 3 PM EST";
  public static ORDER_BY_TOMORROW_MSG = "Order must be placed by tomorrow at 3 PM EST";

  //API Endpoints
  public static URL_HOME_COMMON_CONTENT = "common_content";
  public static URL_HOME_CATEGORIES = "categories_part_types";
  public static URL_HEADER_SLIDER = "categories_part_types/slider_part_types";
  public static URL_HOME_POPULAR_PART_TYPE = "categories_part_types/popular_part_types";
  public static URL_HOME_TOP_RATED_BRANDS = "brands/top_rated_brands";
  public static URL_HOME_POPULAR_CATEGORIES = "categories_part_types/popular_categories";
  public static URL_HOME_POPUPLAR_PART_TYPES = "categories_part_types/shop_by_part_types";
  public static URL_HOME_POPUPLAR_MAKES = "common_content/popular_makes";
  public static URL_HOME_TRUST_PILOT_REVIEWS = "customer_reviews/trust_pilot_reviews";
  public static URL_HOME_SEARCH_PART_TYPES = "part_types/part_types_with_make";
  public static URL_FOOTER_POPULAR_BRAND = "brands/popular_brands";
  public static URL_FOOTER_POPULAR_PARTS = "categories_part_types/popular_part_types"
  public static URL_HOME_BRANDS = "brands";
  public static URL_HOME_POPULAR_BRANDS = "brands/popular_brands"
  public static URL_APPLICATION_FILTER = "part_applications";
  public static URL_HOME_APPLICATION_FILTER = "part_applications/filter";
  public static URL_HOME_HEADER_SEARCH = "part_search/search_items";
  public static URL_APPLICATION_LOCATIONS = "part_applications/locations";
  public static URL_PART_WITH_MAKE = "part_types/part_types_with_make";
  public static URL_REVIEWS = "reviews";
  public static URL_SUBSCRIBE_USER = "subscribers";
  public static URL_USER_LOGIN = "login";
  public static URL_USER_SIGN_UP = "sign_up";
  public static URL_USER_LOGOUT = "logout";
  public static URL_FORGOT_PASSWORD = "reset_password";
  public static URL_PART_SEARCH = "part_search";
  public static URL_CHECK_REGISTERED = "fb";
  public static URL_CART = "cart";
  public static DELETE_ORDER_AND_ADDRESSES = "place_order/delete_order_and_addresses";
  public static URL_MERGE_CART = "cart/merge";
  public static URL_USER_CHECKOUT_DETAILS = "checkout/get_user_details";
  public static URL_SUBMIT_ADDRESS = "addresses";
  public static URL_GET_SHIPPING_DETAILS = "checkout/shipping_information";
  public static URL_PLACE_ORDER = "place_order";
  public static ADD_AFTERPAY_TOKEN = "place_order/add_afterpay_token";
  public static URL_USER_CARDS = "credit_card";
  public static URL_VALID_TOKEN = "users/token";
  public static URL_LANDING_PAGE_DETAILS = "landing_page_results";
  public static URL_DELIVERY_DETAILS = "part_details/get_delivery_date";
  public static URL_KIT_DELIVERY_DETAILS = "kit_details/get_delivery_date";
  public static URL_PAYPAL_CREATE_PAYMENT = "checkout/create_payment";
  public static URL_PAYPAL_APPROVE_PAYMENT = "checkout/approve_payment";
  public static URL_GET_POST_PUT_VEHICLE_ADD = "user_vehicle";
  public static URL_GET_POST_PUT_VEHICLE_ADD_CLEAR_ALL = "user_vehicle/delete_saved_vehicles";
  public static URL_MERGE_ADD_VEHICLE = "user_vehicle/merge";
  // schema work
  public static SCRIPT_TYPE = 'application/ld+json';
  public static SCHEMA_ORG = 'http://schema.org'
  public static ORGANISATION_SCHEMA = {
    "@context": AppConstants.SCHEMA_ORG,
    "@type": "Organization",
    "name": "AutoBuffy",
    "url": "https://www.autobuffy.com",
    "logo": "https://autobuffy.com/assets/images/home/header/header-auto-buffy-logo.svg",
    "description": "Drivers love AutoBuffy. You can find great deals on all kinds of auto parts. Search by make and model of your car, by auto parts brand, or by parts type.",
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "help@autobuffy.com",
      "telephone": "+1 888 477 2530"
    }
   };
  public static GET_PAYMENT_INDENT = "place_order/create_payment_intent";
  public static USER_HOME_PAGE_REVIEWS = [
            {title: 'AMAZING DEAL', fullname: 'Chase Wiggins',comment: 'AMAZING DEAL! Was shocked how fast the item was delivered.  Installed it and it works perfect.  Thank you AutoBuffy! Will shop again.'},
            {title: 'AUTO BUFFY IS THE WAY TO GO', fullname: 'Timothy W',comment: 'Auto buffy is the way to go.  Their website is easy to use.  Bought this for my car and installation was super easy.'},
            {title: 'DID THE JOB AS ADVERTISED', fullname: 'Scott Hagan',comment: "Did the job exactly as advertised. If you're doing your own repair or getting someone else to, this is a pretty good option as it comes with installation help also."}
          ];
}

export const PILOT_REVIEWS = [
      {
        "fullname": "Joe S",
        "rating": 5,
        "comment": "Working on a friend’s suburban and of course he orders wrong parts. No one local has them in stock and CHEVY wanted 1600….. found this site, actually talked to a human (Ryan…new BFF) and he had the parts and FedEx’ed them overnight and I got them at 11:30am the next day. Just plain awesome!",
        "title": "6 Star customer service!",
        "created_at": "Dec, 17, 2021",
        "url": "https://www.trustpilot.com/review/autobuffy.com"

    },

    {
        "fullname": "Johnny Davis",
        "rating": 5,
        "comment": "Thanks ..and Thanks again..customer service is awesome..got a call from them explain and answer all my question..Save me hundreds of dollars..I WILL PURCHASE MORE PARTS AND TELL MY FAMILY AND FRIENDS about the experience I had with AutoBuffy..The part working Better than the one i had on 100%..Im a Happy Camper..",
        "title": "Thanks ..and Thanks again..customer…",
        "created_at": "Dec, 11, 2021",
        "url": "https://www.trustpilot.com/review/autobuffy.com"

    },

    {
        "fullname": "JOHN VANDIVER",
        "rating": 5,
        "comment": "Autobuffy provided the same services that I found on Amazon.com BUT at a much lower-Price. When I had Installment issues they responded quickly and assisted with my issue promptly. Excellent Supplier for my needed CV Axles. Would Highly Recommend and will use AutoBuffy anytime that I have an automotive need in the future",
        "title": "Better prices than Amazon.com with the same delivery",
        "created_at": "Nov, 27, 2021",
        "url": "https://www.trustpilot.com/review/autobuffy.com"

    },

    {
        "fullname": "Tony E.",
        "rating": 5,
        "comment": "Listen, I needed a radiator for my Honda Accord; so I researched & found AutoBuffy. They not only gave me the best price but shipping was FREE! Plus it arrived on the day they said it would..definitely get five stars 🌟 🌟 🌟 🌟 🌟",
        "title": "Awesome Service",
        "created_at": "Nov, 22, 2021",
        "url": "https://www.trustpilot.com/review/autobuffy.com"

    },

    {
        "fullname": "Hector Avellaneda",
        "rating": 5,
        "comment": "Bought part and received fast service. Communication is great and very helpful. Also had quick responses when defective part needed to be replaced, very happy with customer service, support and parts. I will be a returning customer.",
        "title": "Bought part and received fast service",
        "created_at": "Nov, 11, 2021",
        "url": "https://www.trustpilot.com/review/autobuffy.com"

    },

    {
        "fullname": "Robert",
        "rating": 5,
        "comment": "Awesome service by Autobuffy and especially their representative Ryan. Their pricing (Saved me $6,500 on my Lexus LS600 Air Struts). The South Florida dealership quoted me an astonishing $1,900 per Air Strut and a rediculous $2000 in labor costs. I saved 82% on the parts and found a shop to install them for $900. Overall $7,500 in saving. Thank you Ryan and Autobuffy for all the assistance and savings..  Robert",
        "title": "AWESOME PRICING AND SERVICE",
        "created_at": "Nov, 11, 2021",
        "url": "https://www.trustpilot.com/review/autobuffy.com"

    },

    {
        "fullname": "Rowe Abalayan",
        "rating": 5,
        "comment": "WOW! I literally ordered yesterday and got my axles today. Can't wait to put these Cardone axles on. Highly recommended!",
        "title": "Superb experience!!",
        "created_at": "Nov 11, 2021",
        "url": "https://www.trustpilot.com/review/autobuffy.com"

    },

    {
        "fullname": "Diana Walker",
        "rating": 5,
        "comment": "I recently had to order parts for my husband's truck and Ryan helped me with my order and was SUCH a great help! He immediately got my order in and now my husband's truck is up and running. Thank-you, Diana Walker",
        "title": "Very helpful and responsive!",
        "created_at": "Oct, 13, 2021",
        "url": "https://www.trustpilot.com/review/autobuffy.com"

    },

    {
        "fullname": "Jaisal S Rangar",
        "rating": 5,
        "comment": "Used them to order parts for my jeep. Loved the service.",
        "title": "Used them to order parts for my jeep",
        "created_at": "Oct, 13, 2021",
        "url": "https://www.trustpilot.com/review/autobuffy.com"

    }];